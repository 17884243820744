import React, {useContext, useState} from "react";
import {useInput} from "../../../hooks/input-hook";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {withRouter} from "react-router";
import GlobalState from "../../../util/GlobalState";
import {useToasts} from "react-toast-notifications";

const LoginForm = (props) => {
  const [state, setState] = useContext(GlobalState)
  const [showSMSCode, setShowSMSCode] = useState(false)
  const { addToast } = useToasts();

  const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
  const { value:password, bind:bindPassword, reset:resetpassword } = useInput('');
  const { value:smsCode, bind:bindSmsCode } = useInput('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    PatientWolfAPI.login(email, password)
      .then(res => {
        setShowSMSCode(true);
        addToast(res.data.message, { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('Error login in, check your credentials.', { appearance: 'error', autoDismiss: true });
      })
  }

  const handleLogin = (evt) => {
    evt.preventDefault();
    PatientWolfAPI.smsLogin(email, password, smsCode)
      .then(res => {
        localStorage.setItem('access_token', res.data.access_token)
        localStorage.setItem('name', res.data.name)
        setState(state => ({...state, loggedIn: true, user_name: res.data.name}))
        props.history.push('/dashboard')
      })
      .catch(err => {
        addToast('Error login in, incorrect sms code.', { appearance: 'error', autoDismiss: true });
      })
  }

  return (
    <div>
      {!showSMSCode ? <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="loginEmail" className="form-label">Email address</label>
          <input type="email" id="loginEmail" className="form-control" {...bindEmail} />
        </div>
        <div className="mb-3">
          <label htmlFor="loginPassword" className="form-label">Password</label>
          <input type="password" id="loginPassword" className="form-control" {...bindPassword} />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form> : null}
      {showSMSCode ? <form onSubmit={handleLogin}>
        <div className="mb-3">
          <label htmlFor="smsCode" className="form-label">Enter SMS Code</label>
          <input type="number" id="smsCode" className="form-control" {...bindSmsCode} />
        </div>
        <button type="submit" className="btn btn-primary">Login</button>
      </form> : null}
    </div>
  );
}

export default withRouter(LoginForm);