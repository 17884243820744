import React, {useEffect, useState} from "react";
import PatientWolfAPI from "../../../services/patientWolfApi";
import List from "../../../components/list/List";
import './patients.css'
import {useInput} from "../../../hooks/input-hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {Accordion, Card, Form} from "react-bootstrap";
import {useToasts} from "react-toast-notifications";
import Dialog from "../../../components/dialog/dialog";
import AddPhysician from "./addPhysician";

const Physicians = () => {
  const [physicians, setPhysicians] = useState([])
  const [symptoms, setSymptoms] = useState([])
  const [loading, setLoading] = useState(true)
  const [showFilterForm, setShowFiletForm] = useState(false)
  const [regimenList, setRegimenList] = useState([])
  const [inRegimen, setInRegimen] = useState(0)
  const [notInRegimen, setNotInRegimen] = useState(0)
  const [hasSymptom, setHasSymptom] = useState(0)
  const [doesNotHaveSymptom, setDoesNotHaveSymptom] = useState(0)
  const [pagination, setPagination] = useState({
    from: 1,
    to: 15,
    currentPage: 1,
    totalPages: 1,
    total: 0
  })

  const { value:search, bind:bindSearch } = useInput('');
  const { value:biggerThan, bind:bindBiggerThan } = useInput('');
  const { value:smallerThan, bind:bindSmallerThan } = useInput('');
  const { value:filterName, bind:bindFilterName } = useInput('');
  const { addToast } = useToasts();

  const headers = [
    'name',
    'npi',
  ]

  const getPhysicians = function (search, page){
    const filters = {
      bigger_than: biggerThan,
      smaller_than: smallerThan,
      in_regimen: inRegimen,
      not_in_regimen: notInRegimen,
      has_symptom: hasSymptom,
      does_not_have_symptom: doesNotHaveSymptom,
    }
    patientWolfAPI.getPhysicians(search, page)
      .then(res => {
        setPhysicians(res.data.data)
        setPagination({
          from: res.data.from,
          to: res.data.to,
          currentPage: res.data.current_page,
          totalPages: res.data.last_page,
          total: res.data.total
        })
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getSymptoms = () => {
    PatientWolfAPI.getSymptoms()
      .then(res => {
        setSymptoms(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getRegimensList = () => {
    patientWolfAPI.getRegimensList()
      .then(res => {
        setRegimenList(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleChangeRegimenFilter = (value) => {
    setInRegimen(value)
  }

  const handleChangeNotInRegimenFilter = (value) => {
    setNotInRegimen(value)
  }

  const handleChangeHasSymptom = (value) => {
    setHasSymptom(value)
  }

  const handleChangeDoesNotHaveSymptom = (value) => {
    setDoesNotHaveSymptom(value)
  }

  const patientWolfAPI = PatientWolfAPI;
  useEffect(() => {
    getPhysicians(search)
    getRegimensList()
    getSymptoms()
  }, [])


  const handleSubmit = (evt) => {
    evt.preventDefault();
    getPhysicians(search)
  }

  const changePage = (page) => {
    getPhysicians(search, page)
  }

  const createFilter = (evt) => {
    evt.preventDefault()
    const filter = {
      bigger_than: biggerThan,
      smaller_than: smallerThan,
      in_regimen: inRegimen,
      not_in_regimen: notInRegimen
    };
    patientWolfAPI.createFilter(filterName, filter)
        .then(res => {
          addToast("Successfully created filter", { appearance: "success", autoDismiss: true })
        })
        .catch(err => {
          addToast("There was an error creating your filter", { appearance: "error", autoDismiss: true })
        })
  }

  const searchPhysicians = () => {
    getPhysicians(search)
  }

  return  (
    <div className="main-container">
      <Accordion>
        <div className="patients-header">
          <div>
            {/*<Accordion.Toggle className="mr-1" as={Button} eventKey="0">*/}
            {/*  Import*/}
            {/*</Accordion.Toggle>*/}
            {/*<Accordion.Toggle as={Button} eventKey="1">*/}
            {/*  Filters*/}
            {/*</Accordion.Toggle>*/}
          </div>
          <div className="d-flex">
            <div>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="basic-addon2" {...bindSearch} />
                  <button className="btn btn-primary search-button">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </form>
            </div>
            <div className="ml-1">
              <Dialog
                title="Add"
                onCloseDialog={() => getPhysicians()}
              >
                <AddPhysician />
              </Dialog>
            </div>
          </div>
        </div>
        <div>
          <Accordion.Collapse eventKey="1">
            <Card>
              <Card.Body>
                <table className="table table-striped">
                  <tbody>
                  <tr>
                    <td className="align-middle">
                      Age bigger than
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <Form.Control size="sm" className="filter-field-value" type="number" name="bigger-than" id="bigger-than" {...bindBiggerThan} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">
                      Age smaller than
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <Form.Control size="sm" className="filter-field-value" type="number" name="smaller-than" id="smaller-than" {...bindSmallerThan} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">
                      Is on regimen
                    </td>
                    <td className="d-flex justify-content-end">
                      <Form.Control size="sm" className="filter-field-value" as="select" name="regimen" id="regimen" onChange={value => {
                        handleChangeRegimenFilter(value.target.value)
                      }}>
                        <option key={0} value={0}>
                          None selected
                        </option>
                        {regimenList ? regimenList.map(regimen => {
                          return (
                            <option key={regimen.id} value={regimen.id}>
                              {regimen.name}
                            </option>
                          )
                        }) : null}
                      </Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">
                      Is not on regimen
                    </td>
                    <td className="d-flex justify-content-end">
                      <Form.Control size="sm" className="filter-field-value" as="select" name="not-in-regimen" id="not-in-regimen" onChange={value => {
                        handleChangeNotInRegimenFilter(value.target.value)
                      }}>
                        <option key={0} value={0}>
                          None selected
                        </option>
                        {regimenList ? regimenList.map(regimen => {
                          return (
                            <option key={regimen.id} value={regimen.id}>
                              {regimen.name}
                            </option>
                          )
                        }) : null}
                      </Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">
                      Has Symptom
                    </td>
                    <td className="d-flex justify-content-end">
                      <Form.Control size="sm" className="filter-field-value" as="select" name="has-symptom" id="has-symptom" onChange={value => {
                        handleChangeHasSymptom(value.target.value)
                      }}>
                        <option key={0} value={0}>
                          None selected
                        </option>
                        {symptoms ? symptoms.map(symptom => {
                          return (
                            <option key={symptom.id} value={symptom.id}>
                              {symptom.name}
                            </option>
                          )
                        }) : null}
                      </Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">
                      Does not have Symptom
                    </td>
                    <td className="d-flex justify-content-end">
                      <Form.Control size="sm" className="filter-field-value" as="select" name="does-not-have-symptom" id="does-not-have-symptom" onChange={value => {
                        handleChangeDoesNotHaveSymptom(value.target.value)
                      }}>
                        <option key={0} value={0}>
                          None selected
                        </option>
                        {symptoms ? symptoms.map(symptom => {
                          return (
                            <option key={symptom.id} value={symptom.id}>
                              {symptom.name}
                            </option>
                          )
                        }) : null}
                      </Form.Control>
                    </td>
                  </tr>
                  {/*<tr>*/}
                  {/*  <td>*/}
                  {/*    Is on medication*/}
                  {/*  </td>*/}
                  {/*  <td className="d-flex justify-content-end">*/}
                  {/*    <input type="number" name="smaller-than" id="smaller-than" />*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  {/*<tr>*/}
                  {/*  <td>*/}
                  {/*    Is not on medication*/}
                  {/*  </td>*/}
                  {/*  <td className="d-flex justify-content-end">*/}
                  {/*    <input type="number" name="smaller-than" id="smaller-than" />*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  {/*<tr>*/}
                  {/*  <td>*/}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end">
                  <form onSubmit={createFilter}>
                    <div className="input-group mb-3">
                      <button onClick={searchPhysicians} type="button" className="btn btn-primary mr-1">
                        Results
                      </button>

                      {showFilterForm ? <input required type="text" className="form-control filter-form" placeholder="Filter Name" aria-label="Recipient's username" aria-describedby="basic-addon2" {...bindFilterName} /> : null}
                      {showFilterForm ? <button onClick={() => setShowFiletForm(false)} className="btn btn-primary search-button">
                          Cancel
                        </button> : null}
                      {showFilterForm ? <button type="submit" className="btn btn-primary search-button ml-1">
                        Submit
                      </button>: null }
                      {!showFilterForm ? <button className="btn btn-primary mr-1" onClick={() => setShowFiletForm(true)}>
                        Create Filter
                      </button> : null}
                    </div>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Accordion.Collapse>
        </div>
      </Accordion>
      <List withLink={true} headers={headers} items={physicians} pagination={pagination} handleChangePage={changePage} />
    </div>
  )
}

export default Physicians;