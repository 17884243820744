import React, {useEffect, useState} from 'react';
import List from "../../../components/list/List";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";
import PhonePipes from "../../../services/phonePipes";
import './team.css'
import {Link} from "react-router-dom";

const Team = () => {
  const headers = [
    'name',
    'email',
    'phone',
  ]
  const [users, setUsers] = useState([])
  const { addToast } = useToasts();

  const getAdmins = () => {
    PatientWolfAPI.getAdmins()
      .then(res => {
        res.data.data.forEach(element => {
          element.phone = PhonePipes.formatPhone(element.phone)
        })
        setUsers(res.data.data)
      })
      .catch(err => {
        addToast('Error getting users', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getAdmins()
  }, [])

  return (
    <div className="main-container">
      <div className="team-header mr-1">
        <Link to={"/dashboard/team/add"}>
          Add
        </Link>
      </div>
      <List withLink={true} headers={headers} items={users} />
    </div>
  );
}

export default Team;