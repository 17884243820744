import React from "react";
import LoginForm from "../../components/auth/login/loginForm";
import './login.css'

const Login = () => {
  return  (
    <div className="login-wrapper">
      <div className="login-vertical-wrapper">
        <div className="login-container">
          <div className="form-container">
            <div className="login-title">
              Patient Wolf
            </div>
            {/*<p className="login-text">*/}
            {/*  Don't have an account yet? <NavLink to={"/register"}>Sign Up</NavLink>*/}
            {/*</p>*/}
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;