import './App.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Login from "./pages/auth/login";
import Dashboard from "./pages/dashboard/dashboard";
import {useState} from "react";
import GlobalState from "./util/GlobalState";
import PatientQuestionnaire from "./pages/dashboard/diagnoses/regimens/questionnaires/patientQuestionnaire";
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastProvider} from 'react-toast-notifications';

function App() {
  let loggedIn = false;
  if(localStorage.access_token)
    loggedIn = true;

  const [state, setState] = useState({loggedIn: loggedIn});

  return (
    <GlobalState.Provider value={[state, setState]}>
      <ToastProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/task/:outboundTo" exact component={PatientQuestionnaire} />
            {state.loggedIn ? <Route path="/dashboard" component={Dashboard} /> : null}
            <Redirect to={'/login'} />
          </Switch>
        </BrowserRouter>
      </ToastProvider>
    </GlobalState.Provider>
  );
}

export default App;
