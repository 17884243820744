import React, {useEffect, useState} from 'react';
import PatientWolfAPI from "../../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";
import {useInput} from "../../../hooks/input-hook";

const SendQuestionnaire = props => {
  const [questionnaires, setQuestionnaires] = useState([])
  const {addToast} = useToasts()
  const { value: questionnaire, bind: bindQuestionnaire } = useInput(null)

  const getQuestionnaires = () => {
    PatientWolfAPI.getAllQuestionnaires()
      .then(res => {
        setQuestionnaires(res.data.data)
      })
      .catch(err => {
        addToast('Error getting questionnaires', { appearance: 'error', autoDismiss: true });
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    PatientWolfAPI.sendOneQuestionnaire(props.patientId, questionnaire)
      .then(res => {
        addToast('Questionnaire sent to patient', { appearance: 'success', autoDismiss: true });
        props.afterSubmit()
      })
      .catch(err => {
        addToast('Error sending questionnaire', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getQuestionnaires()
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mb-3">
        <div className="col">
          <label htmlFor="questionnaire" className="form-label">Questionnaire</label>
          <select id="questionnaire" className="form-control" {...bindQuestionnaire}>
            <option value={null}>Questionnaire...</option>
            {questionnaires.map(eachQuestionnaire => {
              return <option key={eachQuestionnaire.id} value={eachQuestionnaire.id}>{eachQuestionnaire.name}</option>
            })}
          </select>
        </div>
      </div>
      <button className="btn btn-primary" type="submit">
        Submit
      </button>
    </form>
  )
}

export default SendQuestionnaire