import moment from "moment";

const DatePipes = {
  pipeAgeFromDOB: function (dob){
    return moment().diff(dob, 'years') + " years old"
  },

  friendlyDOB: function (dob){
    return moment(dob).format('MMMM Do, YYYY');
  }
}

export default DatePipes