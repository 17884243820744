import React from "react";
import {withRouter} from "react-router";
import Nav from "../../components/layout/nav/nav";
import './dashboard.css'
import {Redirect, Route, Switch} from "react-router-dom";
import Patients from "./patients/patients";
import Top from "../../components/layout/top";
import Diagnoses from "./diagnoses/diagnoses";
import Questionnaires from "./diagnoses/regimens/questionnaires/questionnaires";
import Regimens from "./diagnoses/regimens/regimens";
import Main from "./main/main";
import Diagnosis from "./diagnoses/diagnosis";
import Regimen from "./diagnoses/regimens/regimen";
import Questionnaire from "./diagnoses/regimens/questionnaires/questionnaire";
import Patient from "./patients/patient";
import PatientRegimen from "./patients/patientRegimen";
import Data from "./data/data";
import Physicians from "./physicians/physicians";
import Physician from "./physicians/physician";
import Edit from "./patients/edit";
import Team from "./team/team";
import Member from "./team/member";
import MemberEdit from "./team/memberEdit";

const Dashboard = (props) => {
  return  (
    <div>
      <Top />
      <div className="dashboard">
        <Nav />
        <Switch>
          <Route path={props.match.url + '/main'} exact component={Main} />
          <Route path={props.match.url + '/team'} exact component={Team} />
          <Route path={props.match.url + '/team/add'} exact component={MemberEdit} />
          <Route path={props.match.url + '/team/:memberId'} exact component={Member} />
          <Route path={props.match.url + '/team/:memberId/edit'} exact component={MemberEdit} />
          <Route path={props.match.url + '/patients'} exact component={Patients} />
          <Route path={props.match.url + '/physicians'} exact component={Physicians} />
          <Route path={props.match.url + '/physicians/:physicianId'} exact component={Physician} />
          <Route path={props.match.url + '/patients/create'} exact component={Edit} />
          <Route path={props.match.url + '/patients/:patientId'} exact component={Patient} />
          <Route path={props.match.url + '/patients/:patientId/edit'} exact component={Edit} />
          <Route path={props.match.url + '/patients/:patientId/regimen/:regimenId'} exact component={PatientRegimen} />
          <Route path={props.match.url + '/diagnoses'} exact component={Diagnoses} />
          <Route path={props.match.url + '/diagnoses/:diagnosisId'} exact component={Diagnosis} />
          <Route path={props.match.url + '/diagnoses/:diagnosisId/regimens/:regimenId'} exact component={Regimen} />
          <Route path={props.match.url + '/diagnoses/:diagnosisId/regimens/:regimenId/questionnaires/:questionnaireId'} exact component={Questionnaire} />
          <Route path={props.match.url + '/questionnaires'} exact component={Questionnaires} />
          <Route path={props.match.url + '/regimens'} exact component={Regimens} />
          <Route path={props.match.url + '/data'} exact component={Data} />
          <Redirect from={props.match.url} to={props.match.url + '/patients'} />
        </Switch>
      </div>
    </div>
  )
}

export default withRouter(Dashboard);