import React from "react";
import {useInput} from "../../../hooks/input-hook";
import {useToasts} from "react-toast-notifications";
import PatientWolfAPI from "../../../services/patientWolfApi";

const CreateSymptom = ({afterSubmit}) => {
  const { value:name, bind:bindName } = useInput('');
  const { value:description, bind:bindDescription } = useInput('');
  const { addToast } = useToasts();

  const createSymptom = (evt) => {
    evt.preventDefault()
    PatientWolfAPI.createSymptom( name, description)
      .then(res => {
        afterSubmit()
        addToast('Successfully created symptpm', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('There was an error creating the symptom', { appearance: 'error', autoDismiss: true });
      })
  }

  return <form onSubmit={createSymptom}>
    <div className="mb-3">
      <label htmlFor="diagnosisName" className="form-label">Name</label>
      <input type="name" id="diagnosisName" className="form-control" {...bindName} />
    </div>
    <div className="mb-3">
      <label htmlFor="diagnosisDescription" className="form-label">Description</label>
      <input type="description" id="diagnosisDescription" className="form-control" {...bindDescription} />
    </div>
    <div className="form-row">
      <button className="btn btn-primary" type="submit">
        Create
      </button>
    </div>
  </form>
}

export default CreateSymptom;