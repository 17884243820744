import React from "react";
import {withRouter} from "react-router-dom";
import {useToasts} from "react-toast-notifications";
import {useInput} from "../../../../hooks/input-hook";
import PatientWolfAPI from "../../../../services/patientWolfApi";

const AddRegimenItem = (props) => {
  const { value:name, bind:bindName } = useInput("");
  const { value:description, bind:bindDescription } = useInput("");
  const { value:day, bind:bindDay } = useInput("");
  const { addToast } = useToasts();

  const handleSubmit = (evt) => {
    evt.preventDefault()
    PatientWolfAPI.addRegimenItem(props.match.params.diagnosisId, props.match.params.regimenId, day, name, description)
      .then(res => {
        addToast('Added regimen item', { appearance: 'success', autoDismiss: true });
        props.afterSubmit()
      })
      .catch(err => {
        addToast('There was an error adding the regimen item', { appearance: 'error', autoDismiss: true });
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="regimenDay" className="form-label">Day</label>
        <input type="number" min="0" id="regimenDay" className="form-control" {...bindDay} />
      </div>
      <div className="mb-3">
        <label htmlFor="regimenName" className="form-label">Name</label>
        <input type="text" id="regimenName" className="form-control" {...bindName} />
      </div>
      <div className="mb-3">
        <label htmlFor="regimenDescription" className="form-label">Description</label>
        <input type="text" id="regimenDescription" className="form-control" {...bindDescription} />
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  )
}

export default withRouter(AddRegimenItem);