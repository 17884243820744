import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {withRouter} from "react-router-dom";
import Avatar from "react-avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEnvelope, faHome} from "@fortawesome/free-solid-svg-icons";
import "./patients.css"
import List from "../../../components/list/List";
import PhonePipes from "../../../services/phonePipes";
import {useToasts} from "react-toast-notifications";
import Dialog from "../../../components/dialog/dialog";
import EditPhysician from "./editPhysician";

const Physician = (props) => {
  const [patient, setPatient] = useState({})
  const [lastCalls, setLastCalls] = useState([])
  const { addToast } = useToasts();

  const getLastCalls = function (){
    PatientWolfAPI.getLastCalls(props.match.params.physicianId)
      .then(res => {
        setLastCalls(res.data.data)
      })
      .catch(err => {
        addToast('Error getting last calls', { appearance: 'error', autoDismiss: true });
      })
  }

  const getPatient = () => {
    PatientWolfAPI.getPatient(props.match.params.physicianId)
      .then(res => {
        res.data.data.contacts.forEach((contact) => {
          if(contact.contact_type_id === 1)
            contact.contact_value = PhonePipes.formatPhone(contact.contact_value)
        })
        setPatient(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getPatient()
    getLastCalls()
  }, [])

  return (
    <div className="main-container">
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="float-right">
              <Dialog title="Edit Physician" buttonType="icon" icon={faEdit} onCloseDialog={() => getPatient()}>
                <EditPhysician initialValue={patient} />
              </Dialog>
            </div>
            <div className="d-flex">
              <div className="mr-5">
                <Avatar name={patient.name} round={true} size="150" />
              </div>
              <div className="d-flex flex-column justify-content-around container-fluid">
                <div className="d-flex justify-content-between">
                  <h1 className="patient-name">
                    {patient.name}
                  </h1>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mr-5 d-flex">
                    <div>
                      <FontAwesomeIcon className="patients-header-icon" icon={faHome} />
                    </div>
                    <p>
                      Address:<br />
                      {patient.address_line_1}<br />
                      {patient.address_line_2 ? patient.address_line_2 + "<br />" : null}
                      {patient.city}, {patient.state}, {patient.zip}
                    </p>
                  </div>
                  <div className="mr-5 d-flex">
                    <div>
                      <FontAwesomeIcon className="patients-header-icon" icon={faEnvelope} />
                    </div>
                    <p>
                      Email: <br />
                      {patient.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <div className="col-6">
          <Card>
            <Card.Header>
              Contacts
            </Card.Header>
            <Card.Body>
              <List withLink={false} items={patient.contacts} headers={['name', 'contact_value']} />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Physician);