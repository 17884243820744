import React from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from 'recharts';

const SimplePieChart = (props) => {
  const colors = [
    "#0E79B2",
    "#BF1363",
    "#F39237",
    "#F39237",
    "#F39237",
    "#F39237",
    "#F39237",
  ]

  const renderLabel = (entry) => {
    return entry.title
  }

  return  (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie data={props.data} dataKey="total" nameKey="title" cx="50%" cy="50%" innerRadius={90} outerRadius={110} label={renderLabel}>
            {
              props.data.length ? props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]}/>
              )) : null
            }
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default SimplePieChart;