import React, {useEffect, useState} from 'react';
import {useInput} from "../../../hooks/input-hook";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {withRouter} from "react-router-dom";
import {useToasts} from "react-toast-notifications";

const MemberEdit = (props) => {
  const { value: name, bind: bindName, setValue: setName } = useInput("")
  const { value: phone, bind: bindPhone, setValue: setPhone } = useInput("")
  const { value: email, bind: bindEmail, setValue: setEmail } = useInput("")
  const { value: password, bind: bindPassword } = useInput("")
  const { addToast } = useToasts()
  const [showDelete, setShowDelete] = useState()

  const handleSubmit = (evt) => {
    evt.preventDefault()
    const member = {
      name,
      phone,
      email,
      password: password ? password : null
    }

    if(props.match.params.memberId){
      PatientWolfAPI.updateAdmin(props.match.params.memberId, member)
        .then(res => {
          addToast('User updated', { appearance: 'success', autoDismiss: true });
          props.history.push("/dashboard/team")
        })
        .catch(err => {
          addToast('Error updating user', { appearance: 'error', autoDismiss: true });
        })
    }else{
      PatientWolfAPI.createAdmin(member)
        .then(res => {
          addToast('User created', { appearance: 'success', autoDismiss: true });
          props.history.push("/dashboard/team")
        })
        .catch(err => {
          addToast('Error creating user', { appearance: 'error', autoDismiss: true });
        })
    }
  }

  const getAdmin = () => {
    if(props.match.params.memberId)
      PatientWolfAPI.getAdmin(props.match.params.memberId)
        .then(res => {
          setName(res.data.data.name)
          setPhone(res.data.data.phone)
          setEmail(res.data.data.email)
        })
        .catch(err => {
          addToast('Error getting user', { appearance: 'error', autoDismiss: true });
        })
  }

  const deleteAdmin = () => {
    PatientWolfAPI.deleteAdmin(props.match.params.memberId)
      .then(res => {
        addToast('User deleted', { appearance: 'success', autoDismiss: true });
        props.history.push("/dashboard/team")
      })
      .catch(err => {
        addToast('Error creating user', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getAdmin()
  }, [])

  return (
    <div className="main-container">
    <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="name" className="form-label">Full Name</label>
            <input type="text" id="name" className="form-control" {...bindName} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="text" id="email" className="form-control" {...bindEmail} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="phone" className="form-label">Phone</label>
            <input type="text" id="phone" className="form-control" {...bindPhone} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="password" className="form-label">Password</label>
            <input type="password" id="password" className="form-control" {...bindPassword} />
          </div>
        </div>
        <button className="btn btn-primary" type="submit">
          Save
        </button>
        {(props.match.params.memberId && !showDelete) ? <button className="btn btn-danger" type="button" onClick={() => setShowDelete(true)}>
          Delete
        </button> : null}
      </form>
      {showDelete ? <div>
        Are you sure?
        <button className="btn btn-danger" onClick={() => deleteAdmin()}>
          Yes
        </button>
        <button className="btn btn-primary" onClick={() => setShowDelete(false)}>
          No
        </button>
      </div> : null}
    </div>
  )
}

export default withRouter(MemberEdit)