import React, {useEffect, useState} from "react";
import PatientWolfAPI from "../../../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";
import List from "../../../../components/list/List";

const Regimens = () => {
  const [regimens, setRegimens] = useState([])
  const { addToast } = useToasts();
  const headers = [
    'name',
    'description'
  ];

  const getRegimens = function (){
    PatientWolfAPI.getRegimens()
      .then(res => {
        setRegimens(res.data.data)
      })
      .catch(err => {
        addToast('Error getting regimens', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getRegimens()
  }, [])

  return  (
    <div className="main-container">
      <List dynamicLink="/dashboard/diagnoses/{diagnosis_id}/regimens/" withLink={true} headers={headers} items={regimens} />
    </div>
  )
}

export default Regimens;