import React from "react";
import NavigationLink from "./link/NavigationLink";
import "./nav.css";
import {
  faCommentMedical,
  faDatabase,
  faMedkit,
  faTachometerAlt,
  faTasks,
  faUserMd,
  faUsers
} from '@fortawesome/free-solid-svg-icons'

const Nav = () => {
  return  (
    <div className="left-nav">
      <NavigationLink to="/dashboard/main" name="Dashboard" icon={faTachometerAlt} />
      <NavigationLink to="/dashboard/patients" name="Patients" icon={faUsers} />
      <NavigationLink to="/dashboard/physicians" name="Physicians" icon={faUserMd} />
      <NavigationLink to="/dashboard/diagnoses" name="Diagnoses" icon={faMedkit} />
      <NavigationLink to="/dashboard/regimens" name="Regimens" icon={faTasks} />
      <NavigationLink to="/dashboard/questionnaires" name="Questionnaires" icon={faCommentMedical} />
      <NavigationLink to="/dashboard/data" name="Data" icon={faDatabase} />
    </div>
  )
}

export default Nav;