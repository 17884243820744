import React, {useContext, useEffect} from "react";
import {withRouter} from "react-router";
import Avatar from "react-avatar";
import GlobalState from "../../util/GlobalState";
import {Dropdown} from "react-bootstrap";
import "./top.css"
import PatientWolfAPI from "../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";

const Top = (props) => {
  const [state, setState] = useContext(GlobalState)
  const { addToast } = useToasts();

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  useEffect(() => {
    if(!state.user_name){
      setState({...state, user_name: localStorage.getItem('name')})
    }
  })


  const handleLogout = () => {
    PatientWolfAPI.logout()
      .then(res => {
        localStorage.clear()
        props.history.push('/login')
        addToast('Successfully logged out', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        localStorage.clear()
        props.history.push('/login')
        addToast('Error logging out of API', { appearance: 'error', autoDismiss: true });
      })
  }

  const navigateTo = (to) => {
    props.history.push(to)
  }

  return  (
    <div className="top-nav">
      <div>
        PatientWolf
      </div>
      <div className="top-right-nav">

        <Dropdown className="top-right-dropdown">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
            <span className="m-1">
              <Avatar name={state.user_name} round="30px" size="30" />
            </span>
            {state.user_name}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigateTo("/dashboard/team")}>
              Team
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/*<button className="btn btn-outline-primary" onClick={handleLogout}>*/}
        {/*  Logout*/}
        {/*</button>*/}
      </div>
    </div>
  )
}

export default withRouter(Top);