const PhonePipes = {
  formatPhone: function (phone){
    if(phone.charAt(0) === '+'){
      phone = phone.substring(1)
    }
    if(phone.charAt(0) === '1'){
      phone = phone.substring(1)
    }

    return "(" + phone.substring(0, 3) + ") " + phone.substring(3,6) + " - " + phone.substring(6)
  }


}

export default PhonePipes;