const MIN_SHOE_SIZE = 5;
const MAX_SHOE_SIZE = 13;

const shoeSizes = [];

for (let count = MIN_SHOE_SIZE; count <= MAX_SHOE_SIZE; count++){
  shoeSizes.push(count)
  shoeSizes.push(count + .5)
}

export default shoeSizes