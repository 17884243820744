import React, {useEffect, useState} from "react";
import {useToasts} from "react-toast-notifications";
import PatientWolfAPI from "../../../../../services/patientWolfApi";
import List from "../../../../../components/list/List";

const Questionnaires = () => {
  const [questionnaires, setQuestionnaires] = useState([])
  const { addToast } = useToasts();
  const headers = [
    'name',
    'description',
    'min_score'
  ];

  const getQuestionnaires = function (){
    PatientWolfAPI.getAllQuestionnaires()
      .then(res => {
        setQuestionnaires(res.data.data)
      })
      .catch(err => {
        addToast('Error getting regimens', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getQuestionnaires()
  }, [])

  return  (
    <div className="main-container">
      <List dynamicLink="/dashboard/diagnoses/{diagnosis_id}/regimens/{regimen_id}/questionnaires/" withLink={true} headers={headers} items={questionnaires} />
    </div>
  )
}

export default Questionnaires;