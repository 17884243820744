import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import PatientWolfAPI from "../../../../services/patientWolfApi";
import {useInput} from "../../../../hooks/input-hook";
import List from "../../../../components/list/List";
import {Accordion, Button, Card} from "react-bootstrap";
import {useToasts} from "react-toast-notifications";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Dialog from "../../../../components/dialog/dialog";
import EditRegimen from "./editRegimen";
import AddRegimenItem from "./addRegimenItem";
import DeleteRegimenItem from "./deleteRegimenItem";
import EditRegimenItem from "./editRegimenItem";

const Regimen = (props) => {
  const [regimen, setRegimen] = useState({});
  const { value:name, bind:bindName } = useInput('');
  const { value:description, bind:bindDescription } = useInput('');
  const { value:minScore, bind:bindMinScore } = useInput('');
  const { addToast } = useToasts();

  const headers = [
    'name',
    'description',
    'min_score',
  ];

  const patientHeaders = [
    'name',
    'city',
    'state',
    'date_of_birth',
  ]

  useEffect(() => {
    getRegimen()
  }, [])

  const getRegimen = function (){
    PatientWolfAPI.getRegimen(props.match.params.diagnosisId, props.match.params.regimenId)
      .then(res => {
        setRegimen(res.data.data)
      })
      .catch(err => {
        addToast('Error getting regimen info', { appearance: 'error', autoDismiss: true });
      })
  }

  const createQuestionnaire = function (evt){
    evt.preventDefault()
    PatientWolfAPI.createQuestionnaire(
      props.match.params.diagnosisId,
      props.match.params.regimenId,
      name,
      description,
      minScore
    )
      .then(res => {
        addToast('Successfully created questionnaire', { appearance: 'success', autoDismiss: true });
        getRegimen()
      })
      .catch(err => {
        addToast('Error creating questionnaire', { appearance: 'error', autoDismiss: true });
      })
  }

  return  (
    <div className="main-container">
      <div className="patients-header">
        <h1>
          {regimen.name}
        </h1>
        <Dialog
          buttonType="icon"
          icon={faEdit}
          title="Edit Diagnosis"
          onCloseDialog={() => getRegimen()}
        >
          <EditRegimen initialValue={regimen} />
        </Dialog>
      </div>
      <p>
        {regimen.description}
      </p>
      <Accordion>
        <div className="patients-header">
          <Accordion.Toggle className="mr-1" as={Button} eventKey="0">
            Create Questionnaire
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey="0">
          <Card>
            <Card.Body>
              <form onSubmit={createQuestionnaire}>
                <div className="form-row">
                  <div className="col">
                    <input type="text" className="form-control" placeholder="Name" {...bindName} />
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" placeholder="Description" {...bindDescription} />
                  </div>
                  <div className="col">
                    <input type="number" className="form-control" min="0" max="100" placeholder="Minimum Score (0 - 100%)" {...bindMinScore} />
                  </div>
                  <button className="btn btn-primary" type="submit">
                    Create
                  </button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Accordion.Collapse>
      </Accordion>
      <List linkAppend="/questionnaires/" withLink={true} headers={headers} items={regimen.questionnaires} />
      {(regimen.patients && regimen.patients.length) ? <div>
        <h1>
          Qualified Patients
        </h1>
        <List
          dynamicLink="/dashboard/patients/"
          withLink={true}
          headers={patientHeaders}
          items={regimen.patients}
        />
      </div> : null}
      <div>
        <h1>
          Schedule
        </h1>
        <table className="table">
          <thead>
          <tr>
            <th>
              Day
            </th>
            <th>
              Item title
            </th>
            <th>
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          {regimen.regimen_items ? regimen.regimen_items.map(regimenItem => {
            return <tr key={regimenItem.id}>
              <td>
                {regimenItem.day}
              </td>
              <td>
                {regimenItem.name}
              </td>
              <td className="d-flex">
                <Dialog
                    icon={faEdit}
                    buttonType="icon"
                    title="Edit Schedule Item"
                    onCloseDialog={() => getRegimen()}
                >
                  <EditRegimenItem regimenItem={regimenItem} />
                </Dialog>
                <Dialog
                    icon={faTrash}
                    buttonType="icon"
                    title="Delete Schedule Item"
                    onCloseDialog={() => getRegimen()}
                >
                  <DeleteRegimenItem regimenItem={regimenItem} />
                </Dialog>
              </td>
            </tr>
          }) : null}
          </tbody>
        </table>
        <Dialog
            icon={faEdit}
            title="Add Schedule Item"
            onCloseDialog={() => getRegimen()}
        >
          <AddRegimenItem />
        </Dialog>
      </div>
    </div>
  )
}

export default withRouter(Regimen);