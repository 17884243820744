import React, {useState} from "react";
import PatientWolfAPI from "../../../../services/patientWolfApi";
import './import.css'
import {useToasts} from "react-toast-notifications";

const ImportPatients = ({afterImport}) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [inputMessage, setInputMessage] = useState("No file selected...")
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false)

  const submitForm = function (evt){
    setLoading(true)
    evt.preventDefault();
    PatientWolfAPI.importPatients(selectedFile)
      .then(res => {
        afterImport()
        addToast(res.data.message, { appearance: "success", autoDismiss: true })
        setInputMessage("No file selected...")
        setSelectedFile("")
        setLoading(false)
      })
      .catch(err => {
        addToast("There was an error importing the patients.", { appearance: "error", autoDismiss: true })
        setLoading(false)
      })
  }

  const onFileChange = function (evt){
    setInputMessage(evt.target.files[0].name)
    setSelectedFile(evt.target.files[0])
  }

  return (
    <div>
      <form>
        <div className="input-group">
          <div className="custom-file" id="customFile" lang="es">
            <input type="file" onChange={onFileChange} className="custom-file-input" id="importInputFile" aria-describedby="fileHelp" />
            <label className="custom-file-label" htmlFor="importInputFile">
              {inputMessage}
            </label>
          </div>

          <button disabled={loading} onClick={submitForm} className="btn btn-outline-secondary ml-1" type="button" id="inputGroupFileAddon04">
            {loading ? <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div> : 'Import'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ImportPatients;