import React, {useEffect, useState} from "react";
import PatientWolfAPI from "../../../../../services/patientWolfApi";
import {withRouter} from "react-router";
import {Card} from "react-bootstrap";
import {useToasts} from "react-toast-notifications";

const PatientQuestionnaire = (props) => {
  const [questionnaire, setQuestionnaire] = useState({})
  const [questions, setQuestions] = useState([]);
  const [patientId, setPatientId] = useState(0);
  const { addToast } = useToasts();

  const [patientAnswers, setPatientAnswers] = useState([])

  useEffect(() => {
    getQuestionnaire();
  }, [])

  //getQuestionnaire
  const getQuestionnaire = function (){
    PatientWolfAPI.getPatientQuestionnaire(props.match.params.outboundTo)
      .then(res => {
        setQuestionnaire(res.data.data.questionnaire)
        setQuestions(res.data.data.questionnaire.questions)
        setPatientId(res.data.data.patient_id)
      })
      .catch(err => {
        addToast("Error getting questionnaire", { appearance: "error", autoDismiss: true })
      })
  }

  //submit answers
  const submitAnswers = function (evt){
    evt.preventDefault()
    PatientWolfAPI.submitPatientQuestionnaire(questionnaire.id, patientId, patientAnswers)
      .then(res => {
        addToast(res.data.message, { appearance: "success", autoDismiss: true })
      })
      .catch(err => {
        addToast("Error saving patient answers", { appearance: "error", autoDismiss: true })
      })
  }

  const handleAnswerChange = function (event){
    const answer = {
      question_id: event.target.name,
      answer_id: event.target.value,
    }

    const newAnswers = [...patientAnswers]

    newAnswers.forEach((eachAnswer, key) => {
      if(eachAnswer.question_id == answer.question_id)
        newAnswers.splice(key, 1)
    })

    newAnswers.push(answer)

    setPatientAnswers(newAnswers)
  }

  return  (
    <Card>
      <Card.Body>
        <h1>
          {questionnaire.name}
        </h1>
        <p>
          {questionnaire.description}
        </p>
        <form onSubmit={submitAnswers}>
          {questions.map((question) => {
            return (
              <Card>
                <Card.Body>
                  <h3>
                    {question.title}
                  </h3>
                  <p>
                    {question.description}
                  </p>
                  {question.answers.map((answer, key) => {
                    return (
                      <div key={answer.id} className="form-check">
                        <input onInput={handleAnswerChange} className="form-check-input" type="radio" value={answer.id} name={question.id} id={answer.id} />
                        <label className="form-check-label" htmlFor={answer.id}>{answer.description}</label>
                      </div>
                    )
                  })}
                </Card.Body>
              </Card>
            )
          })}
          <button className="btn btn-primary mt-3" type="submit">
            Submit
          </button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default withRouter(PatientQuestionnaire);