import React from "react";
import {useInput} from "../../../hooks/input-hook";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";
import states from "../../../services/states";
import "react-datepicker/dist/react-datepicker.css";

const AddPhysician = (props) => {
  const { value:name, bind:bindName } = useInput("");
  const { value:npi, bind:bindNpi } = useInput("");
  const { value:phone, bind:bindPhone } = useInput("");
  const { value:fax, bind:bindFax } = useInput("");
  const { value:email, bind:bindEmail } = useInput("");
  const { value:password, bind:bindPassword } = useInput("");
  const { value:addressLine1, bind:bindAddressLine1 } = useInput("");
  const { value:addressLine2, bind:bindAddressLine2 } = useInput("");
  const { value:city, bind:bindCity } = useInput("");
  const { value:state, bind:bindState } = useInput("");
  const { value:zip, bind:bindZip } = useInput("");
  const { addToast } = useToasts();

  const handleSubmit = (evt) => {
    evt.preventDefault()

    const create = {
      name,
      npi,
      phone,
      fax,
      email,
      password,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city,
      state,
      zip,
    }

    PatientWolfAPI.createPhysician(create)
      .then(res => {
        props.afterSubmit()
        addToast('Physician created', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('There was an error creating the Physician', { appearance: 'error', autoDismiss: true });
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mb-3">
        <div className="col">
          <label htmlFor="name" className="form-label">Name</label>
          <input type="text" id="name" className="form-control" {...bindName} />
        </div>
        <div className="col">
          <label htmlFor="npi" className="form-label">NPI</label>
          <input type="text" id="npi" className="form-control" {...bindNpi} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label htmlFor="email" className="form-label">Email</label>
          <input type="text" id="email" className="form-control" {...bindEmail} />
        </div>
        <div className="col">
          <label htmlFor="phone" className="form-label">Phone</label>
          <input type="text" id="phone" className="form-control" {...bindPhone} />
        </div>
        <div className="col">
          <label htmlFor="fax" className="form-label">Fax</label>
          <input type="text" id="fax" className="form-control" {...bindFax} />
        </div>
      </div>
      {/*<div className="mb-3">*/}
      {/*  <label htmlFor="password" className="form-label">Password</label>*/}
      {/*  <input type="password" id="password" className="form-control" {...bindPassword} />*/}
      {/*</div>*/}
      <div className="row mb-3">
        <div className="col">
          <label htmlFor="addressLine1" className="form-label">Address Line 1</label>
          <input type="text" id="addressLine1" className="form-control" {...bindAddressLine1} />
        </div>
        <div className="col">
          <label htmlFor="addressLine2" className="form-label">Address Line 2</label>
          <input type="text" id="addressLine2" className="form-control" {...bindAddressLine2} />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <label htmlFor="city" className="form-label">City</label>
          <input type="text" id="city" className="form-control" {...bindCity} />
        </div>
        <div className="col">
          <label htmlFor="state" className="form-label">State</label>
          <select id="state" className="form-control" {...bindState}>
            <option value={null}>State...</option>
            {states.map(eachState => {
              return <option key={eachState} value={eachState}>{eachState}</option>
            })}
          </select>
        </div>
        <div className="col">
          <label htmlFor="zip" className="form-label">Zip</label>
          <input type="text" id="zip" className="form-control" {...bindZip} />
        </div>
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  )
}

export default AddPhysician;