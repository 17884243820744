import React, {useEffect, useState} from "react";
import {useInput} from "../../../hooks/input-hook";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";
import states from "../../../services/states";
import maritalStatuses from "../../../services/maritalStatus";
import heights from "../../../services/heights";
import shoeSizes from "../../../services/shoeSizes";
import shirtSizes from "../../../services/shirtSizes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-autocomplete"
import deviceStatuses from "../../../services/deviceStatuses";
import {Card} from "react-bootstrap";
import {withRouter} from "react-router-dom";

const Edit = (props) => {
  const { value:name, bind:bindName, setValue: setName } = useInput("");
  const { value:phone, bind:bindPhone, setValue: setPhone } = useInput("");
  const { value:email, bind:bindEmail, setValue: setEmail } = useInput("");
  const { value:ssn, bind:bindSsn, setValue: setSsn } = useInput("");
  const { value:iniConnect, bind:bindIniConnect, setValue: setIniConnect } = useInput("");
  const { value:password, bind:bindPassword, setValue: setPassword } = useInput("");
  const { value:addressLine1, bind:bindAddressLine1, setValue: setAddressLine1 } = useInput("");
  const { value:addressLine2, bind:bindAddressLine2, setValue: setAddressLine2 } = useInput("");
  const { value:city, bind:bindCity, setValue: setCity } = useInput("");
  const { value:state, bind:bindState, setValue: setState } = useInput("");
  const { value:zip, bind:bindZip, setValue: setZip } = useInput("");
  const { value:maritalStatus, bind:bindMaritalStatus, setValue: setMaritalStatus } = useInput("");
  const { value:gender, bind:bindGender, setValue: setGender } = useInput("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const { value:weight, bind:bindWeight, setValue: setWeight } = useInput("");
  const { value:height, bind:bindHeight, setValue: setHeight } = useInput("");
  const { value:shoeSize, bind:bindShoeSize, setValue: setShowSize } = useInput("");
  const { value:shirtSize, bind:bindShirtSize, setValue: setShirtSize } = useInput("");
  const { value:insuranceNumber, bind:bindInsuranceNumber, setValue: setInsuranceNumber } = useInput("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const { value:medicaid, bind:bindMedicaid, setValue: setMedicaid } = useInput(null);
  const [insurance, setInsurance] = useState("")
  const [insurances, setInsurances] = useState([])
  const [physicians, setPhysicians] = useState([])
  const [prescriptions, setPrescriptions] = useState([])
  const [prescription, setPrescription] = useState("")
  const [physician, setPhysician] = useState("")
  const { addToast } = useToasts();

  const { value:physicianName, bind:bindPhysicianName } = useInput("");
  const { value:physicianNpi, bind:bindPhysicianNpi } = useInput("");
  const { value:physicianPhone, bind:bindPhysicianPhone } = useInput("");
  const { value:physicianFax, bind:bindPhysicianFax } = useInput("");
  const { value:physicianEmail, bind:bindPhysicianEmail } = useInput("");
  const { value:physicianAddressLine1, bind:bindPhysicianAddressLine1 } = useInput("");
  const { value:physicianAddressLine2, bind:bindPhysicianAddressLine2 } = useInput("");
  const { value:physicianCity, bind:bindPhysicianCity } = useInput("");
  const { value:physicianState, bind:bindPhysicianState } = useInput("");
  const { value:physicianZip, bind:bindPhysicianZip } = useInput("");

  const { value:dexcomStatus, bind:bindDexcomStatus, setValue: setDexcomStatus } = useInput(null);
  const { value:dexcomTracking, bind:bindDexcomTracking, setValue: setDexcomTracking } = useInput("");
  const { value:livecareStatus, bind:bindLivecareStatus, setValue: setLivecareStatus } = useInput(null);
  const { value:livecareTracking, bind:bindLivecareTracking, setValue: setLivecareTracking } = useInput("");

  const { value:pharmacyName, bind:bindPharmacyName, setValue: setPharmacyName } = useInput("");
  const { value:pharmacyPhone, bind:bindPharmacyPhone, setValue: setPharmacyPhone } = useInput("");
  const { value:pharmacyAddressLine1, bind:bindPharmacyAddressLine1, setValue: setPharmacyAddressLine1 } = useInput("");
  const { value:pharmacyAddressLine2, bind:bindPharmacyAddressLine2, setValue: setPharmacyAddressLine2 } = useInput("");
  const { value:pharmacyCity, bind:bindPharmacyCity, setValue: setPharmacyCity } = useInput("");
  const { value:pharmacyState, bind:bindPharmacyState, setValue: setPharmacyState } = useInput("");
  const { value:pharmacyZip, bind:bindPharmacyZip, setValue: setPharmacyZip } = useInput("");

  const { value:bestTimeToContact, bind:bindBestTimeToContact, setValue: setBestTimeToContact } = useInput(null);
  // const { value:contactFrom, bind:bindContactFrom } = useInput(null);
  // const { value:contactTo, bind:bindContactTo } = useInput(null);

  const handleSubmit = (evt) => {
    let contactFrom = 0;
    let contactTo = 24;
    evt.preventDefault()
    switch (bestTimeToContact){
      case 'mornings':
        contactFrom = 9
        contactTo = 12
        break;
      case 'afternoons':
        contactFrom = 12
        contactTo = 17
        break;
      case 'evenings':
        contactFrom = 17
        contactTo = 21
        break;
    }

    const create = {
      name,
      ssn,
      email,
      phone,
      effective_date: insurance ? effectiveDate.toDateString() : null,
      in_iconnect: iniConnect,
      password,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city,
      state,
      zip,
      pharmacy_name: pharmacyName,
      pharmacy_address_line_1: pharmacyAddressLine1,
      pharmacy_address_line_2: pharmacyAddressLine2,
      pharmacy_city: pharmacyCity,
      pharmacy_state: pharmacyState,
      pharmacy_zip: pharmacyZip,
      pharmacy_phone: pharmacyPhone,
      contact_from: contactFrom,
      contact_to: contactTo,
      marital_status: maritalStatus,
      gender,
      date_of_birth: dateOfBirth ? dateOfBirth.toDateString() : null,
      weight,
      height,
      prescriptions: prescription,
      shoe_size: shoeSize,
      shirt_size: shirtSize,
      insurance: insurance,
      insurance_number: insuranceNumber,
      medicaid: parseInt(medicaid),
      physician_npi: (physician === 'New...' ? physicianNpi : physician),
      physician_name: physicianName,
      physician_phone: physicianPhone,
      physician_fax: physicianFax,
      physician_email: physicianEmail,
      physician_address_1: physicianAddressLine1,
      physician_address_2: physicianAddressLine2,
      physician_city: physicianCity,
      physician_state: physicianState,
      physician_zipcode: physicianZip,
      dexcom_status: dexcomStatus,
      dexcom_tracking: dexcomTracking,
      livecare_status: livecareStatus,
      livecare_tracking: livecareTracking,
    }

    if(props.match.params.patientId){
      PatientWolfAPI.updatePatient(props.match.params.patientId, create)
        .then(res => {
          addToast('Patient updated', { appearance: 'success', autoDismiss: true });
          props.history.push('/dashboard/patients/' + res.data.data.id)
        })
        .catch(err => {
          handleErrors(err)
          addToast('There was an error updating the Patient', { appearance: 'error', autoDismiss: true });
        })
    }else{
      PatientWolfAPI.createPatient(create)
        .then(res => {
          addToast('Patient created', { appearance: 'success', autoDismiss: true });
          props.history.push('/dashboard/patients/' + res.data.data.id)
        })
        .catch(err => {
          handleErrors(err)
          addToast('There was an error creating the Patient', { appearance: 'error', autoDismiss: true });
        })
    }
  }

  const handleErrors = (errors) => {
    if(errors.response.data && errors.response.data.errors)
      Object.entries(errors.response.data.errors).forEach(element => {
        addToast(element[1][0], { appearance: 'error', autoDismiss: true });
      })
  }

  const getAllPhysicians = () => {
    PatientWolfAPI.getAllPhysicians()
      .then(res => {
        let physiciansArray = res.data.data;
        physiciansArray.unshift({
          name: '',
          npi: 'New...'
        })
        setPhysicians(physiciansArray)
      })
      .catch(err => {
        addToast('There was an error getting the physicians', { appearance: 'error', autoDismiss: true });
      })
  }

  const getAllPrescriptions = () => {
    PatientWolfAPI.getAllPrescriptions()
      .then(res => {
        setPrescriptions(res.data.data)
      })
      .catch(err => {
        addToast('There was an error getting the prescriptions', { appearance: 'error', autoDismiss: true });
      })
  }

  const getAllInsurances = () => {
    PatientWolfAPI.getAllInsurances()
      .then(res => {
        setInsurances(res.data.data)
      })
      .catch(err => {
        addToast('There was an error getting the insurances', { appearance: 'error', autoDismiss: true });
      })
  }

  const getPatient = () => {
    PatientWolfAPI.getPatient(props.match.params.patientId)
      .then(res => {
        res.data.data.contacts.forEach((contact) => {
          if(contact.contact_type_id === 1){
            setPhone(contact.contact_value)
          }
        })
        setName(res.data.data.name)
        setSsn(res.data.data.ssn)
        setIniConnect(res.data.data.in_iconnect)
        setMaritalStatus(res.data.data.marital_status)
        setGender(res.data.data.gender)
        //date of birth
        let patientDateOfBirth = new Date(res.data.data.date_of_birth)
        patientDateOfBirth.setDate(patientDateOfBirth.getDate() + 1);
        setDateOfBirth(patientDateOfBirth)
        setWeight(res.data.data.weight)
        setHeight(res.data.data.height)
        setShowSize(res.data.data.shoe_size)
        setShirtSize(res.data.data.shirt_size)
        setEmail(res.data.data.email)
        switch (res.data.data.contact_from) {
          case 0:
            setBestTimeToContact(null)
            break;
          case 9:
            setBestTimeToContact('mornings')
            break;
          case 12:
            setBestTimeToContact('afternoons')
            break;
          case 17:
            setBestTimeToContact('evenings')
            break;
        }
        setAddressLine1(res.data.data.address_line_1)
        setAddressLine2(res.data.data.address_line_2)
        setCity(res.data.data.city)
        setState(res.data.data.state)
        setZip(res.data.data.zip)
        setPharmacyName(res.data.data.pharmacy_name)
        setPharmacyPhone(res.data.data.pharmacy_phone)
        setPharmacyAddressLine1(res.data.data.pharmacy_address_line_1)
        setPharmacyAddressLine2(res.data.data.pharmacy_address_line_2)
        setPharmacyCity(res.data.data.pharmacy_city)
        setPharmacyState(res.data.data.pharmacy_state)
        setPharmacyZip(res.data.data.pharmacy_zip)
        res.data.data.prescriptions.forEach(element => {
          setPrescription(element.name)
        })
        res.data.data.insurances.forEach(element => {
          setInsurance(element.insurance.name)
          setInsuranceNumber(element.insurance_number)
          //effective date
          setMedicaid(element.medicaid)
        })
        if(res.data.data.physician)
          setPhysician(res.data.data.physician.npi)
        res.data.data.devices.forEach(element => {
          if(element.device_id === 1){
            setDexcomStatus(element.status)
            setDexcomTracking(element.tracking_number)
          }
          if(element.device_id === 2){
            setLivecareStatus(element.status)
            setLivecareTracking(element.tracking_number)
          }
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if(props.match.params.patientId){
      getPatient()
    }
    getAllPhysicians()
    getAllPrescriptions()
    getAllInsurances()
  } , [])

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Card className="m-3">
          <Card.Header>
            Patient
          </Card.Header>
          <Card.Body>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="name" className="form-label">Full Name</label>
                <input required type="text" id="name" className="form-control" {...bindName} />
              </div>
              <div className="col">
                <label htmlFor="ssn" className="form-label">SSN</label>
                <input type="text" id="ssn" className="form-control" {...bindSsn} />
              </div>
              <div className="col">
                <label htmlFor="iniConnect" className="form-label">In iConnect</label>
                <select id="iniConnect" className="form-control" {...bindIniConnect}>
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="maritalStatus" className="form-label">Marital Status</label>
                <select id="maritalStatus" className="form-control" {...bindMaritalStatus}>
                  <option value={null}>Marital Status...</option>
                  {maritalStatuses.map(maritalStatus => {
                    return <option key={maritalStatus} value={maritalStatus}>{maritalStatus}</option>
                  })}
                </select>
              </div>
              <div className="col">
                <label htmlFor="gender" className="form-label">Gender</label>
                <select id="gender" className="form-control" {...bindGender}>
                  <option value={null}>Gender...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="col datepicker-bootstrap-styling">
                <div>
                  <label htmlFor="dateOfBirth" className="form-label">Date of Birth</label>
                </div>
                <DatePicker selected={dateOfBirth} onChange={(date) => setDateOfBirth(date)} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="weight" className="form-label">Weight</label>
                <div className="input-group">
                  <input type="number" id="weight" className="form-control" {...bindWeight} />
                  <div className="input-group-append">
                    <span className="input-group-text" id="weightAppend">lbs</span>
                  </div>
                </div>
              </div>
              <div className="col">
                <label htmlFor="height" className="form-label">Height</label>
                <select id="height" className="form-control" {...bindHeight}>
                  <option value={null}>Height...</option>
                  {heights.map(height => {
                    return <option key={height.height_value} value={height.height_value}>{height.height_title}</option>
                  })}
                </select>
              </div>
              <div className="col">
                <label htmlFor="shoeSize" className="form-label">Shoe Size</label>
                <select id="shoeSize" className="form-control" {...bindShoeSize}>
                  <option value={null}>Shoe Size...</option>
                  {shoeSizes.map(shoeSize => {
                    return <option key={shoeSize} value={shoeSize}>{shoeSize}</option>
                  })}
                </select>
              </div>
              <div className="col">
                <label htmlFor="shirtSize" className="form-label">Shirt Size</label>
                <select id="shirtSize" className="form-control" {...bindShirtSize}>
                  <option value={null}>Shirt Size...</option>
                  {shirtSizes.map(shirtSize => {
                    return <option key={shirtSize} value={shirtSize}>{shirtSize}</option>
                  })}
                </select>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="m-3">
          <Card.Header>
            Contact
          </Card.Header>
          <Card.Body>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="email" className="form-label">Email</label>
                <input required type="text" id="email" className="form-control" {...bindEmail} />
              </div>
              <div className="col">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input required type="text" id="phone" className="form-control" {...bindPhone} />
              </div>
              <div className="col">
                <label htmlFor="bestTimeToContact" className="form-label">Best time to contact</label>
                <select id="bestTimeToContact" className="form-control" {...bindBestTimeToContact}>
                  <option value={"anytime"}>Anytime</option>
                  <option value={"morning"}>Mornings</option>
                  <option value={"afternoon"}>Afternoons</option>
                  <option value={"evenings"}>Evenings</option>
                </select>
              </div>
            </div>
            {/*<div className="mb-3">*/}
            {/*  <label htmlFor="password" className="form-label">Password</label>*/}
            {/*  <input type="password" id="password" className="form-control" {...bindPassword} />*/}
            {/*</div>*/}
          </Card.Body>
        </Card>
        <Card className="m-3">
          <Card.Header>
            Address
          </Card.Header>
          <Card.Body>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="addressLine1" className="form-label">Address Line 1</label>
                <input type="text" id="addressLine1" className="form-control" {...bindAddressLine1} />
              </div>
              <div className="col">
                <label htmlFor="addressLine2" className="form-label">Address Line 2</label>
                <input type="text" id="addressLine2" className="form-control" {...bindAddressLine2} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="city" className="form-label">City</label>
                <input type="text" id="city" className="form-control" {...bindCity} />
              </div>
              <div className="col">
                <label htmlFor="state" className="form-label">State</label>
                <select id="state" className="form-control" {...bindState}>
                  <option value={null}>State...</option>
                  {states.map(eachState => {
                    return <option key={eachState} value={eachState}>{eachState}</option>
                  })}
                </select>
              </div>
              <div className="col">
                <label htmlFor="zip" className="form-label">Zip</label>
                <input type="text" id="zip" className="form-control" {...bindZip} />
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="m-3">
          <Card.Header>
            Pharmacy
          </Card.Header>
          <Card.Body>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="pharmacyName" className="form-label">Name</label>
                <input type="text" id="pharmacyName" className="form-control" {...bindPharmacyName} />
              </div>
              <div className="col">
                <label htmlFor="pharmacyPhone" className="form-label">Phone</label>
                <input type="text" id="pharmacyPhone" className="form-control" {...bindPharmacyPhone} />
              </div>
              <div className="col">
                <label htmlFor="pharmacyAddressLine1" className="form-label">Address Line 1</label>
                <input type="text" id="pharmacyAddressLine1" className="form-control" {...bindPharmacyAddressLine1} />
              </div>
              <div className="col">
                <label htmlFor="pharmacyAddressLine2" className="form-label">Address Line 2</label>
                <input type="text" id="pharmacyAddressLine2" className="form-control" {...bindPharmacyAddressLine2} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="pharmacyCity" className="form-label">City</label>
                <input type="text" id="pharmacyCity" className="form-control" {...bindPharmacyCity} />
              </div>
              <div className="col">
                <label htmlFor="pharmacyState" className="form-label">State</label>
                <select id="pharmacyState" className="form-control" {...bindPharmacyState}>
                  <option value={null}>State...</option>
                  {states.map(eachState => {
                    return <option key={eachState} value={eachState}>{eachState}</option>
                  })}
                </select>
              </div>
              <div className="col">
                <label htmlFor="pharmacyZip" className="form-label">Zip</label>
                <input type="text" id="pharmacyZip" className="form-control" {...bindPharmacyZip} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col datepicker-bootstrap-styling">
                <div>
                  <label htmlFor="weight" className="form-label">Prescription</label>
                </div>
                <div>
                  <Autocomplete
                    wrapperStyle={{
                      display: 'inline-block', width: '100%'
                    }}
                    getItemValue={(item) => {
                      return item.name
                    }}
                    items={prescriptions.filter(element => {
                      return (element.name.toLowerCase().includes(prescription.toLowerCase()))
                    })}
                    renderItem={(item, isHighlighted) =>
                      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item.name}
                      </div>
                    }
                    value={prescription}
                    onChange={(e) => {
                      setPrescription(e.target.value)
                    }}
                    onSelect={(val) => {
                      setPrescription(val)
                    }}
                  />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="m-3">
          <Card.Header>
            Insurance
          </Card.Header>
          <Card.Body>
            <div className="row mb-3 datepicker-bootstrap-styling">
              <div className="col">
                <div>
                  <label htmlFor="weight" className="form-label">Insurance</label>
                </div>
                <div>
                  <Autocomplete
                    wrapperStyle={{
                      display: 'inline-block', width: '100%'
                    }}
                    getItemValue={(item) => {
                      return item.name
                    }}
                    items={insurances.filter(element => element.name.toLowerCase().includes(insurance.toLowerCase()))}
                    renderItem={(item, isHighlighted) =>
                      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item.name}
                      </div>
                    }
                    value={insurance}
                    onChange={(e) => {
                      setInsurance(e.target.value)
                    }}
                    onSelect={(val) => {
                      setInsurance(val)
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <label htmlFor="memberId" className="form-label">Member ID</label>
                <input type="text" id="memberId" className="form-control" {...bindInsuranceNumber} />
              </div>
              <div className="col datepicker-bootstrap-styling">
                <div>
                  <label htmlFor="effectiveDate" className="form-label">Effective Date</label>
                </div>
                <DatePicker selected={effectiveDate} onChange={(date) => setEffectiveDate(date)} />
              </div>
              <div className="col">
                <label htmlFor="medicaid" className="form-label">Medicaid</label>
                <select id="medicaid" className="form-control" {...bindMedicaid}>
                  <option value={null}>Medicaid...</option>
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </select>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="m-3">
          <Card.Header>
            Physician
          </Card.Header>
          <Card.Body>
            <div className="row mb-3 datepicker-bootstrap-styling">
              <div className="col">
                <div>
                  <label htmlFor="weight" className="form-label">Physician NPI</label>
                </div>
                <div>
                  <Autocomplete
                    wrapperStyle={{
                      display: 'inline-block', width: '100%'
                    }}
                    getItemValue={(item) => {
                      return item.npi
                    }}
                    items={physicians.filter(element => {
                      return (element.npi.toLowerCase() === 'new...' || element.npi.toLowerCase().includes(physician.toLowerCase()) || element.name.toLowerCase().includes(physician.toLowerCase()))
                    })}
                    renderItem={(item, isHighlighted) =>
                      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item.name + " " + item.npi}
                      </div>
                    }
                    value={physician}
                    onChange={(e) => {
                      setPhysician(e.target.value)
                    }}
                    onSelect={(val) => {
                      setPhysician(val)
                    }}
                  />
                </div>
              </div>
            </div>
            {physician === 'New...' ? <div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="physicianName" className="form-label">Physician Full Name</label>
                  <input type="text" id="physicianName" className="form-control" {...bindPhysicianName} />
                </div>
                <div className="col">
                  <label htmlFor="physicianNpi" className="form-label">NPI</label>
                  <input required type="text" id="physicianNpi" className="form-control" {...bindPhysicianNpi} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="physicianEmail" className="form-label">Physician Email</label>
                  <input type="text" id="physicianEmail" className="form-control" {...bindPhysicianEmail} />
                </div>
                <div className="col">
                  <label htmlFor="physicianPhone" className="form-label">Physician Phone</label>
                  <input type="text" id="physicianPhone" className="form-control" {...bindPhysicianPhone} />
                </div>
                <div className="col">
                  <label htmlFor="physicianFax" className="form-label">Physician Fax</label>
                  <input type="text" id="physicianFax" className="form-control" {...bindPhysicianFax} />
                </div>
              </div>
              {/*<div className="mb-3">*/}
              {/*  <label htmlFor="password" className="form-label">Password</label>*/}
              {/*  <input type="password" id="password" className="form-control" {...bindPassword} />*/}
              {/*</div>*/}
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="physicianAddressLine1" className="form-label">Physician Address Line 1</label>
                  <input type="text" id="physicianAddressLine1" className="form-control" {...bindPhysicianAddressLine1} />
                </div>
                <div className="col">
                  <label htmlFor="physicianAddressLine2" className="form-label">Physician Address Line 2</label>
                  <input type="text" id="physicianAddressLine2" className="form-control" {...bindPhysicianAddressLine2} />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="physicianCity" className="form-label">Physician City</label>
                  <input type="text" id="physicianCity" className="form-control" {...bindPhysicianCity} />
                </div>
                <div className="col">
                  <label htmlFor="physicianState" className="form-label">Physician State</label>
                  <select id="physicianState" className="form-control" {...bindPhysicianState}>
                    <option value={null}>Physician State...</option>
                    {states.map(eachState => {
                      return <option key={eachState} value={eachState}>{eachState}</option>
                    })}
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="physicianZip" className="form-label">Physician Zip</label>
                  <input type="text" id="physicianZip" className="form-control" {...bindPhysicianZip} />
                </div>
              </div>
            </div> : null}
          </Card.Body>
        </Card>
        <Card className="m-3">
          <Card.Header>
            Devices
          </Card.Header>
          <Card.Body>
            <div className="row mb-3">
              <div className="col">
                <label htmlFor="dexcomStatus" className="form-label">Dexcom Status</label>
                <select id="dexcomStatus" className="form-control" {...bindDexcomStatus}>
                  <option value={null}>Dexcom Status...</option>
                  {deviceStatuses.map(eachStatus => {
                    return <option key={eachStatus} value={eachStatus}>
                      {eachStatus}
                    </option>
                  })}
                </select>
              </div>
              <div className="col">
                <label htmlFor="dexcomTracking" className="form-label">Tracking Number</label>
                <input type="text" id="dexcomTracking" className="form-control" {...bindDexcomTracking} />
              </div>
              <div className="col">
                <label htmlFor="livecareStatus" className="form-label">Livecare Status</label>
                <select id="livecareStatus" className="form-control" {...bindLivecareStatus}>
                  <option value={null}>Livecare Status...</option>
                  {deviceStatuses.map(eachStatus => {
                    return <option key={eachStatus} value={eachStatus}>
                      {eachStatus}
                    </option>
                  })}
                </select>
              </div>
              <div className="col">
                <label htmlFor="livecareTracking" className="form-label">Tracking Number</label>
                <input type="text" id="livecareTracking" className="form-control" {...bindLivecareTracking} />
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-center m-3">
          <button type="submit" className="btn btn-primary">Save</button>
        </div>
      </form>
    </div>
  )
}

export default withRouter(Edit);