import React, {useEffect, useState} from "react";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {withRouter} from "react-router";
import {useInput} from "../../../hooks/input-hook";
import List from "../../../components/list/List";
import {Accordion, Button, Card} from "react-bootstrap";
import {useToasts} from "react-toast-notifications";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import Dialog from "../../../components/dialog/dialog";
import EditDiagnosis from "./editDiagnosis";

const Diagnosis = (props) => {
  const [diagnosis, setDiagnosis] = useState({})
  const { value:name, bind:bindName } = useInput('');
  const { value:description, bind:bindDescription } = useInput('');
  const { addToast } = useToasts();

  const headers = [
    'name',
    'description'
  ];

  const getDiagnosis = function (){
    PatientWolfAPI.getDiagnosis(props.match.params.diagnosisId)
      .then(res => {
        const symptomsArray = []
        res.data.symptoms.forEach(element => {
          symptomsArray.push(element.name)
        })
        const diagnosisResponse = {...res.data, symptomsString: symptomsArray.join(',')}
        setDiagnosis(diagnosisResponse)
      })
      .catch(err => {
        addToast('There was an error fetching the diagnosis', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getDiagnosis();
  }, [])

  const createRegimen = (evt) => {
    evt.preventDefault()
    PatientWolfAPI.createRegimen(props.match.params.diagnosisId, name, description)
      .then(res => {
        getDiagnosis()
        addToast('Successfully created the regimen', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('There was an error creating the regimen', { appearance: 'error', autoDismiss: true });
      })
  }

  return  (
    <div className="main-container">
      <div className="patients-header">
        <h1>
          {diagnosis.name}
        </h1>
        <Dialog
          buttonType="icon"
          icon={faEdit}
          title="Edit Diagnosis"
          onCloseDialog={() => getDiagnosis()}
        >
          <EditDiagnosis initialValue={diagnosis} />
        </Dialog>
      </div>
      <p>
        {diagnosis.description}
      </p>
      <Accordion>
        <div className="patients-header">
          <Accordion.Toggle className="mr-1" as={Button} eventKey="0">
            Create Regimen
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey="0">
          <Card>
            <Card.Body>
              <form onSubmit={createRegimen}>
                <div className="form-row">
                  <div className="col">
                    <input required type="text" className="form-control" placeholder="Name" {...bindName} />
                  </div>
                  <div className="col">
                    <input required type="text" className="form-control" placeholder="Description" {...bindDescription} />
                  </div>
                  <button className="btn btn-primary" type="submit">
                    Create
                  </button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Accordion.Collapse>
      </Accordion>
      <h2>
        Regimens
      </h2>
      <List linkAppend="/regimens/" withLink={true} headers={headers} items={diagnosis.regimens} />
    </div>
  )
}

export default withRouter(Diagnosis);