import React from "react";
import {withRouter} from "react-router-dom";
import {useToasts} from "react-toast-notifications";
import {useInput} from "../../../../hooks/input-hook";
import PatientWolfAPI from "../../../../services/patientWolfApi";

const EditRegimen = (props) => {
  const { value:name, bind:bindName } = useInput(props.initialValue.name);
  const { value:description, bind:bindDescription } = useInput(props.initialValue.description);
  const { addToast } = useToasts();

  const handleSubmit = (evt) => {
    evt.preventDefault()
    PatientWolfAPI.updateRegimen(props.match.params.diagnosisId, props.match.params.regimenId, name, description)
      .then(res => {
        addToast('Regimen updated', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('There was an error updating the regimen', { appearance: 'error', autoDismiss: true });
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="diagnosisName" className="form-label">Name</label>
        <input type="name" id="diagnosisName" className="form-control" {...bindName} />
      </div>
      <div className="mb-3">
        <label htmlFor="diagnosisDescription" className="form-label">Description</label>
        <input type="description" id="diagnosisDescription" className="form-control" {...bindDescription} />
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  )
}

export default withRouter(EditRegimen);