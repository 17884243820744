import React, {useEffect, useState} from "react";
import PatientWolfAPI from "../../../services/patientWolfApi";
import List from "../../../components/list/List";
import {useInput} from "../../../hooks/input-hook";
import {Accordion, Button, Card} from "react-bootstrap";
import "./diagnoses.css"
import {useToasts} from "react-toast-notifications";

const Diagnoses = () => {
  const [diagnoses, setDiagnoses] = useState([]);
  const [init, setInit] = useState(false)
  const [showCreateForm, setShowCreateForm] = useState(false)
  const { value:name, bind:bindName } = useInput('');
  const { value:description, bind:bindDescription } = useInput('');
  const { value:symptoms, bind:bindSymptoms } = useInput('');
  const { addToast } = useToasts();

  const [pagination, setPagination] = useState({
    from: 1,
    to: 15,
    currentPage: 1,
    totalPages: 1,
    total: 0
  })

  const changePage = (page) => {
    console.log('Changed Page')
  }

  const headers = [
      'name'
  ]

  useEffect(() => {
    getDiagnoses()
  }, [init])

  const getDiagnoses = () => {
    PatientWolfAPI.getDiagnoses()
      .then(res => {
        setDiagnoses(res.data.data)
        setPagination({
          from: res.data.from,
          to: res.data.to,
          currentPage: res.data.current_page,
          totalPages: res.data.last_page,
          total: res.data.total
        })
        setInit(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const createDiagnosis = function (evt){
    evt.preventDefault()
    PatientWolfAPI.createDiagnosis(name, description, symptoms)
      .then(res => {
        console.log(res.data.data)
        getDiagnoses();
        addToast('Diagnosis created successfully', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('There was an error creating your diagnosis', { appearance: 'error', autoDismiss: true });
      })
  }

  return  (
    <div className="main-container">
      <Accordion>
        <div className="diagnoses-header">
          <Accordion.Toggle as={Button} eventKey="0">
            Create
          </Accordion.Toggle>
        </div>
        <Accordion.Collapse eventKey="0">
          <Card>
            <Card.Body>
              <div className="create-diagnosis-container">
                <form onSubmit={createDiagnosis}>
                  <div className="form-row">
                    <div className="col">
                      <input required type="text" className="form-control" placeholder="Name" {...bindName} />
                    </div>
                    <div className="col">
                      <input required type="text" className="form-control" placeholder="Description" {...bindDescription} />
                    </div>
                    <div className="col">
                      <input type="text" className="form-control" placeholder="Symptoms" {...bindSymptoms} />
                    </div>
                    <button className="btn btn-primary" type="submit">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </Card.Body>
          </Card>
        </Accordion.Collapse>
      </Accordion>
      <List withLink={true} headers={headers} items={diagnoses} pagination={pagination} handleChangePage={changePage} />
    </div>
  )
}

export default Diagnoses;