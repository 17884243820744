import React, {useEffect, useState} from "react";
import PatientWolfAPI from "../../../services/patientWolfApi";
import Dialog from "../../../components/dialog/dialog";
import CreateSymptom from "./createSymptom";
import {Form} from "react-bootstrap";
import {useToasts} from "react-toast-notifications";

const Data = () => {
  const [symptoms, setSymptoms] = useState([])
  const [externalSymptoms, setExternalSymptoms] = useState([])
  const { addToast } = useToasts();

  const getUnmappedExternalSymptoms = () => {
    PatientWolfAPI.getExternalSymptoms()
      .then(res => {
        setExternalSymptoms(res.data.data)
      })
      .catch(err => {
        addToast('There was an error fetching the external symptoms', { appearance: 'error', autoDismiss: true });
      })
  }

  const getSymptoms = () => {
    PatientWolfAPI.getSymptoms()
      .then(res => {
        setSymptoms(res.data.data)
      })
      .catch(err => {
        addToast('There was an error fetching the symptoms', { appearance: 'error', autoDismiss: true });
      })
  }

  const updateExternalSymptom = (evt) => {
    evt.preventDefault()
    if(evt.target.symptom_id.value != 0){
      PatientWolfAPI.updateExternalSymptom(evt.target.external_symptom_id.value, evt.target.symptom_id.value)
        .then(res => {
          getUnmappedExternalSymptoms()
          addToast('External Symptom updated. ' + res.data.data.patient_symptoms_added + ' patients updated.', { appearance: 'success', autoDismiss: true });
        })
        .catch(err => {
          addToast('There was an error updating the external symptom', { appearance: 'error', autoDismiss: true });
        })
    }else{
      addToast('No symptom selected', { appearance: 'error', autoDismiss: true });
    }
  }

  useEffect(() => {
    getUnmappedExternalSymptoms()
    getSymptoms()
  }, [])

  return <div className="main-container">
    <h1>
      Data
    </h1>
    <div className="d-flex justify-content-between">
      <h3>
        Unmapped External Symptoms
      </h3>
      <Dialog title="Create Symptom" onCloseDialog={() => getSymptoms()}>
        <CreateSymptom />
      </Dialog>
    </div>
    <table className="table">
      <thead>
      <tr>
        <th>
          Name
        </th>
        <th>
          Mapped to
        </th>
      </tr>
      </thead>
      <tbody>
      {externalSymptoms ? externalSymptoms.map(externalSymptom => {
        return <tr key={externalSymptom.id}>
          <td>
            {externalSymptom.name}
          </td>
          <td>
            <form onSubmit={updateExternalSymptom} className="d-flex">
              <input type="hidden" id="external_symptom_id" name="external_symptom_id" value={externalSymptom.id} />
              <Form.Control required size="sm" className="filter-field-value mr-2" as="select" name="symptom_id" id="symptom_id">
                <option key={0} value={0}>
                  None selected
                </option>
                {symptoms ? symptoms.map(symptom => {
                  return (
                    <option key={symptom.id} value={symptom.id}>
                      {symptom.name}
                    </option>
                  )
                }) : null}
              </Form.Control>
              <button className="btn btn-primary btn-sm" type="submit">
                Submit
              </button>
            </form>
          </td>
        </tr>
      }): null}
      </tbody>
    </table>
  </div>
}

export default Data;