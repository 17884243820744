import axios from "axios";
import history from "../history";

const api = axios.create({
  baseURL: process.env.REACT_APP_PATIENT_WOLF_API,
  timeout: 60000
})

api.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem('access_token')

  if (jwtToken) {
    config.headers['Authorization'] = "Bearer " + jwtToken
  }

  return config
}, error => {
  Promise.reject(error)
})

api.interceptors.response.use((response) => {
  return Promise.resolve(response)
}, (error) => {
  if(error.response.status === 401){
    localStorage.clear()
    history.push('/login')
    window.location.reload();
  }

  return Promise.reject(error)
});

const PatientWolfAPI = {
  login: function (email, password){
    const data = {
      email,
      password
    }
    return api.post('auth/login', data)
  },

  logout: function (){
    return api.post('auth/logout')
  },

  smsLogin: function (email, password, smsCode){
    const data = {
      email,
      password,
      sms_code: smsCode
    }
    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'auth/sms_login', data)
  },

  getCurrentUser: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'auth/user')
  },

  getPatients: function (search = '', page = 1, filters = {}){
    const config = {
      params: {
        search: search,
        page: page,
        filters: JSON.stringify(filters)
      }
    };
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'patients', config)
  },

  getPhysicians: function (search = '', page = 1){
    const config = {
      params: {
        search: search,
        page: page,
      }
    };
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'physicians', config)
  },

  getAllPhysicians: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'physicians/all')
  },

  getAllPrescriptions: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'prescriptions/all')
  },

  getAllInsurances: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'insurances/all')
  },

  getPatient: function (patientId){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'patients/' + patientId)
  },

  getPatientRegimen: function (patientId, regimenId){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'patients/' + patientId + '/regimen/' + regimenId)
  },

  importPatients: function (file){
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "csv_file",
      file,
      file.name
    );

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'patients/import', formData)
  },

  createFilter: function (name, filter){
    const data = {
      name: name,
      filter: filter
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'filters', data)
  },

  getDiagnoses: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses')
  },

  getDiagnosis: function (diagnosisId){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId)
  },

  createDiagnosis: function (name, description, symptoms){
    const data = {
      name: name,
      description: description,
      symptoms: symptoms
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses', data)
  },

  updateDiagnosis: function (diagnosisId, name, description, symptoms){
    const data = {
      name: name,
      description: description,
      symptoms: symptoms
    }

    return api.patch(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId, data)
  },

  createPatient: function (create){
    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'patients', create)
  },

  createPhysician: function (create){
    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'physicians', create)
  },

  updatePatient: function (patientId, update){
    return api.put(process.env.REACT_APP_PATIENT_WOLF_API + 'patients/' + patientId, update)
  },

  updateExternalSymptom: function (externalSymptomId, symptomId){
    const data = {
      symptom_id: symptomId
    }

    return api.patch(process.env.REACT_APP_PATIENT_WOLF_API + 'symptoms/external/' + externalSymptomId, data)
  },

  createRegimen: function (diagnosisId, name, description){
    const data = {
      name: name,
      description: description
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen', data)
  },

  createSymptom: function (name, description){
    const data = {
      name: name,
      description: description
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'symptoms', data)
  },

  getRegimen: function (diagnosisId, regimenId){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId)
  },

  getRegimens: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'regimens/')
  },

  getRegimensList: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'regimens/list')
  },

  getAllQuestionnaires: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'questionnaires/')
  },

  updateRegimen: function (diagnosisId, regimenId, name, description){
    const data = {
      name,
      description
    }

    return api.patch(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId, data)
  },

  addRegimenItem: function (diagnosisId, regimenId, day, name, description){
    const data = {
      day,
      name,
      description
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId + '/item', data)
  },

  updateRegimenItem: function (diagnosisId, regimenId, regimenItemId, day, name, description){
    const data = {
      day,
      name,
      description
    }

    return api.patch(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId + '/item/' + regimenItemId, data)
  },

  deleteRegimenItem: function (diagnosisId, regimenId, regimenItemId){
    return api.delete(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId + '/item/' + regimenItemId)
  },

  createQuestionnaire: function (diagnosisId, regimenId, name, description, minScore){
    const data = {
      name,
      description,
      min_score: minScore
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId + '/questionnaire', data)
  },

  getPatientQuestionnaire: function (outboundTo){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'agents/questionnaire?outbound_to=' + outboundTo)
  },

  getQuestionnaire: function (diagnosisId, regimenId, questionnaireId){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId + '/questionnaire/' + questionnaireId)
  },

  getFilters: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'filters')
  },

  updateQuestionnaire: function (diagnosisId, regimenId, questionnaireId, name, description, minScore, questions){
    const data = {
      name,
      description,
      min_score: minScore,
      questions
    }

    return api.put(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId + '/questionnaire/' + questionnaireId, data)
  },

  sendQuestionnaire: function (diagnosisId, regimenId, questionnaireId, patientFilterId){
    const data = {
      patient_filter_id: patientFilterId
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'diagnoses/' + diagnosisId + '/regimen/' + regimenId + '/questionnaire/' + questionnaireId + '/send', data)
  },

  sendOneQuestionnaire: function (patientId, questionnaireId){
    const data = {
      patient_id: patientId,
      questionnaire_id: questionnaireId,
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'questionnaires/send', data)
  },

  submitPatientQuestionnaire: function (questionnaireId, patientId, answers){
    const data = {
      patient_id: patientId,
      answers
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'agents/questionnaire/' + questionnaireId + '/submit', data)
  },

  getMainStats: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'stats/main')
  },

  getTotalPatientsByAge: function (){
    const token = localStorage.getItem('access_token')
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'stats/patients_by_age', config)
  },

  getPatientsBySymptom: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'stats/patients_by_symptom')
  },

  getLastCalls: function (patientId = 0){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'stats/last_calls?patient_id=' + patientId)
  },

  getMostCommonAnswers: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'stats/most_common_answers')
  },

  getSymptoms: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'symptoms')
  },

  getExternalSymptoms: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'symptoms/external')
  },

  getAdmins: function (){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'admins')
  },

  getAdmin: function (id){
    return api.get(process.env.REACT_APP_PATIENT_WOLF_API + 'admins/' + id)
  },

  createAdmin: function (create){
    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'admins/', create)
  },

  updateAdmin: function (id, update){
    return api.put(process.env.REACT_APP_PATIENT_WOLF_API + 'admins/' + id, update)
  },

  deleteAdmin: function (id){
    return api.delete(process.env.REACT_APP_PATIENT_WOLF_API + 'admins/' + id)
  },

  updatePatientRegimenItem: function (patientId, regimenId, regimenItemId, completed){
    const data = {
      completed
    }

    return api.post(process.env.REACT_APP_PATIENT_WOLF_API + 'patients/' + patientId + '/regimen/' + regimenId + '/item/' + regimenItemId, data)
  }
}

export default PatientWolfAPI