import React, {useEffect, useState} from "react";
import BoxStat from "../../../components/stats/box/box";
import {Card} from "react-bootstrap";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";
import BrushBarChart from "../../../components/stats/brushBarChart/brushBarChart";
import List from "../../../components/list/List";
import SimplePieChart from "../../../components/stats/SimplePieChart/SimplePieChart";

const Main = () => {
  const [mainStats, setMainStats] = useState({})
  const [patientsByAge, setPatientsByAge] = useState({})
  const [patientsBySymptom, setPatientsBySymptom] = useState({})
  const [lastCalls, setLastCalls] = useState([])
  const [mostCommonAnswers, setMostCommonAnswers] = useState([])
  const { addToast } = useToasts();

  const getMainStats = function (){
    PatientWolfAPI.getMainStats()
      .then(res => {
        setMainStats(res.data.data)
      })
      .catch(err => {
        addToast('Error getting main stats', { appearance: 'error', autoDismiss: true });
      })
  }

  const getLastCalls = function (){
    PatientWolfAPI.getLastCalls()
      .then(res => {
        setLastCalls(res.data.data)
      })
      .catch(err => {
        addToast('Error getting last calls', { appearance: 'error', autoDismiss: true });
      })
  }

  const getMostCommonAnswers = function (){
    PatientWolfAPI.getMostCommonAnswers()
      .then(res => {
        setMostCommonAnswers(res.data.data)
      })
      .catch(err => {
        addToast('Error getting most common answers', { appearance: 'error', autoDismiss: true });
      })
  }

  const getPatientsByAge = function (){
    PatientWolfAPI.getTotalPatientsByAge()
      .then(res => {
        setPatientsByAge(res.data.data)
      })
      .catch(err => {
        addToast('Error getting patients by age stats', { appearance: 'error', autoDismiss: true });
      })
  }

  const getPatientsBySymtom = function (){
    PatientWolfAPI.getPatientsBySymptom()
      .then(res => {
        setPatientsBySymptom(res.data.data)
      })
      .catch(err => {
        addToast('Error getting patients by symptoms stats', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getMainStats()
    getPatientsByAge()
    getPatientsBySymtom()
    getLastCalls()
    getMostCommonAnswers()
  }, []);

  return  (
    <div className="main-container">
      <div className="d-flex justify-content-between">
        <BoxStat title="Total Patients" description="Total patients in Database" value={mainStats.total_patients} />
        <BoxStat title="Calls" description="Total calls made to patients" value={mainStats.total_calls} />
        <BoxStat title="Qualified" description="Total patients qualified for regimens" value={mainStats.total_qualified_patients} />
        <BoxStat title="Regimens" description="Total Regimens" value={mainStats.total_regimens} />
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="col-6">
          <Card>
            <Card.Header>
              Patients by age
            </Card.Header>
            <Card.Body>
              <BrushBarChart data={patientsByAge} />
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Header>
              Patients by Symptom
            </Card.Header>
            <Card.Body>
              {/*<BrushBarChart data={patientsBySymptom} />*/}
              <SimplePieChart data={patientsBySymptom} />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="col-6">
          <Card>
            <Card.Header>
              Last 5 Calls
            </Card.Header>
            <Card.Body>
              <List headers={['name', 'outbound_to']} items={lastCalls} />
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Header>
              Most Commons Answers
            </Card.Header>
            <Card.Body>
              <List headers={['title', 'description', 'total']} items={mostCommonAnswers} />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Main;