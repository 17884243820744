import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if(!process.env.REACT_APP_ENVIRONMENT.includes('local')){
  Sentry.init({
    dsn: "https://ba319863a2ef495296da43b84dc34f21@o922599.ingest.sentry.io/5869598",
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
