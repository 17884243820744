import React from "react";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const BrushBarChart = (props) => {
  return  (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer>
        <BarChart
          data={props.data}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="title" />
          <YAxis />
          <Tooltip />
          {/*<Legend />*/}
          <Bar dataKey="total" barSize={10} fill="#8884d8" />
          {/*<Bar dataKey="uv" fill="#82ca9d" />*/}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default BrushBarChart;