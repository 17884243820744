import React from "react";
import './list.css'
import {withRouter} from "react-router";

const List = (props) => {
  let linkAppend = '/'
  if(props.linkAppend)
    linkAppend = props.linkAppend

  const cleanHeader = function (str){
    str = str.replace(/_/g, " ");
    return str.replace(/\b\w/g, l => l.toUpperCase())
  }

  let pages = [];
  if(props.pagination && props.pagination.total && props.pagination.total > 0)
    for (let i = 1; i <= props.pagination.totalPages; i++){
      pages.push(i);
    }

  return  (
    <div>
      {
        props.items ? <table className="table table-hover">
          <thead>
          <tr>
            {props.headers.map(header => {
              return (
                <th className="table-header" key={header}>
                  {cleanHeader(header)}
                </th>
              )
            })}
          </tr>
          </thead>
          <tbody>
          {props.items.map(item => {
            let dynamicLink = '/'
            if(props.dynamicLink)
              dynamicLink = props.dynamicLink

            return (
              <tr key={item.id} className={props.withLink ? "table-pointer" : ""}>
                {props.headers.map(header => {
                  let url = props.match.url + linkAppend + item.id
                  if(dynamicLink !== "/"){
                    let keys = dynamicLink.split(/[{}]/);
                    for(let i = 0; i < keys.length; i++){
                      dynamicLink = dynamicLink.replace("{" + keys[i] + "}", item[keys[i]])
                    }

                    url = dynamicLink + item.id
                  }

                  return (
                    <td key={item.id + header} onClick={() => {if(props.withLink) props.history.push(url)}}>
                      {item[header]}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          {props.items.length === 0 &&
            <tr>
              <td>
                {props.noItemMessage ? props.noItemMessage : "No items"}
              </td>
            </tr>}
          </tbody>
        </table> : null
      }
      {(props.pagination && props.pagination.total && props.pagination.total > 0)? <div className="pagination">
        <div>
          Showing from {props.pagination.from} to {props.pagination.to} of {props.pagination.total}
        </div>
        <div>
          Select Page
          <select name="page" id="page" onChange={value => {
            props.handleChangePage(value.target.value)
          }}>
            {pages.map(page => {
              return (
                <option key={page} value={page}>
                  {page}
                </option>
              )
            })}
          </select>
        </div>
      </div> : null}
    </div>
  )
}

export default withRouter(List);