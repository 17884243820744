import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const NavigationLink = (props) => {
  let classes = "container nav-item mb-1"

  if(props.location.pathname.includes(props.to)){
    classes = classes + " nav-link-active"
  }

  return  (
    <NavLink to={props.to}>
      <div className={classes}>
        <div className="row d-flex justify-content-center">
          <div className="col-2 d-flex justify-content-center">
            <FontAwesomeIcon className="nav-icon" icon={props.icon} />
          </div>
          <div className="col-8">
            {props.name}
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default withRouter(NavigationLink);