import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";

const PatientRegimen = ({match}) => {
  const [regimen, setRegimen] = useState({})
  const [patientRegimenItems, setPatientRegimenItems] = useState([])
  const { addToast } = useToasts();

  //get patient regimen
  const getPatientRegimen = () => {
    PatientWolfAPI.getPatientRegimen(match.params.patientId, match.params.regimenId)
      .then(res => {
        setRegimen(res.data.data.regimen)
        setPatientRegimenItems(res.data.data.patient_regimen_items)
      })
      .catch(err => {
        addToast('Error getting Patient Regimen', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getPatientRegimen()
  }, []);

    //mark as completed
  const updatePatientRegimenItem = (evt, regimenItemId) => {
    PatientWolfAPI.updatePatientRegimenItem(match.params.patientId, match.params.regimenId, regimenItemId, evt.target.checked)
      .then(res => {
        getPatientRegimen()
        addToast('Patient scheduled item updated', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('Error getting updating patient schedule item', { appearance: 'error', autoDismiss: true });
      })
  }

  //mark as pending

  return <div className="main-container">
    <h1>
      {regimen.name}
    </h1>
    <h2>
      {regimen.description}
    </h2>
    <table className="table">
      <thead>
      <tr>
        <th>
          Day
        </th>
        <th>
          Item title
        </th>
        <th>
          Completed
        </th>
      </tr>
      </thead>
      <tbody>
      {patientRegimenItems ? patientRegimenItems.map(regimenItem => {
        let completed = regimenItem.completed
        if(completed !== 1)
          completed = 0

        return <tr key={regimenItem.id}>
          <td>
            {regimenItem.day}
          </td>
          <td>
            {regimenItem.name}
          </td>
          <td>
            <div className="form-check">
              <input defaultChecked={completed} className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={(evt) => updatePatientRegimenItem(evt, regimenItem.id)} />
            </div>
            {/*{regimenItem.completed}*/}
          </td>
        </tr>
      }) : null}
      </tbody>
    </table>
  </div>
}

export default withRouter(PatientRegimen);