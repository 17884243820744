import React, {useEffect, useState} from 'react';
import {Card} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import Avatar from "react-avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {useToasts} from "react-toast-notifications";
import PhonePipes from "../../../services/phonePipes";

const Member = (props) => {
  const [member, setMember] = useState({
    name: "",
    email: "",
    phone: "",
  })
  const { addToast } = useToasts();

  const getAdmin = () => {
    PatientWolfAPI.getAdmin(props.match.params.memberId)
      .then(res => {
        setMember(res.data.data)
      })
      .catch(err => {
        addToast('Error getting user', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getAdmin()
  }, [])

  return (
    <div className="main-container">
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="float-right">
              <Link to={props.history.location.pathname + "/edit"}>
                Edit
              </Link>
            </div>
            <div className="d-flex">
              <div className="mr-5">
                <Avatar name={member.name} round={true} size="150" />
              </div>
              <div className="d-flex flex-column justify-content-around container-fluid">
                <div className="d-flex justify-content-between">
                  <h1 className="patient-name">
                    {member.name}
                  </h1>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mr-5 d-flex">
                    <div>
                      <FontAwesomeIcon className="patients-header-icon" icon={faEnvelope} />
                    </div>
                    <p>
                      Email: <br />
                      {member.email}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mr-5 d-flex">
                    <div>
                      <FontAwesomeIcon className="patients-header-icon" icon={faPhone} />
                    </div>
                    <p>
                      Phone: <br />
                      {PhonePipes.formatPhone(member.phone)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default withRouter(Member);