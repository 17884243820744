import React from "react";
import {withRouter} from "react-router-dom";
import {useToasts} from "react-toast-notifications";
import {useInput} from "../../../../../hooks/input-hook";
import PatientWolfAPI from "../../../../../services/patientWolfApi";

const EditQuestionnaire = (props) => {
  const { value:name, bind:bindName } = useInput(props.initialValue.name);
  const { value:description, bind:bindDescription } = useInput(props.initialValue.description);
  const { value:minScore, bind:bindMinScore } = useInput(props.initialValue.min_score);
  const { addToast } = useToasts();

  const handleSubmit = (evt) => {
    evt.preventDefault()
    PatientWolfAPI.updateQuestionnaire(props.match.params.diagnosisId, props.match.params.regimenId, props.match.params.questionnaireId, name, description, minScore, props.initialValue.questions)
      .then(res => {
        addToast('Questionnaire updated', { appearance: 'success', autoDismiss: true });
      })
      .catch(err => {
        addToast('There was an error updating the questionnaire', { appearance: 'error', autoDismiss: true });
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="questionnaireName" className="form-label">Name</label>
        <input type="text" id="questionnaireName" className="form-control" {...bindName} />
      </div>
      <div className="mb-3">
        <label htmlFor="dquestionnaireDescription" className="form-label">Description</label>
        <input type="text" id="questionnaireDescription" className="form-control" {...bindDescription} />
      </div>
      <div className="mb-3">
        <label htmlFor="questionnaireMinScore" className="form-label">Min Score</label>
        <input type="number" id="questionnaireMinScore" className="form-control" {...bindMinScore} />
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
  )
}

export default withRouter(EditQuestionnaire);