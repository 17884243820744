const MIN_FEET = 4;
const MAX_FEET = 7;

let heights = [];

for (let count = MIN_FEET; count <= MAX_FEET; count++){
  for (let inches = 0; inches < 12; inches++){
    heights.push({
      height_title: `${count} ft ${inches} in`,
      height_value: count * 12 + inches
    })
  }
}

export default heights