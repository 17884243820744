import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import PatientWolfAPI from "../../../../../services/patientWolfApi";
import {Accordion, Button, Card} from "react-bootstrap";
import {useToasts} from "react-toast-notifications";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import Dialog from "../../../../../components/dialog/dialog";
import EditQuestionnaire from "./editQuestionnaire";

const Questionnaire = (props) => {
  const [questionnaire, setQuestionnaire] = useState({})
  const [filters, setFilters] = useState([])
  const [patientFilterId, setPatientFilterId] = useState(null)
  const [questions, setQuestions] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    getQuestionnaire()
    getFilters()
  }, [])

  const getQuestionnaire = function (){
    PatientWolfAPI.getQuestionnaire(props.match.params.diagnosisId, props.match.params.regimenId, props.match.params.questionnaireId)
      .then(res => {
        setQuestionnaire(res.data.data)
        setQuestions(res.data.data.questions)
      })
      .catch(err => {
        console.log(err)
        addToast("Error getting questionnaire", { appearance: "error", autoDismiss: true })
      })
  }

  const getFilters = function (){
    PatientWolfAPI.getFilters()
      .then(res => {
        setFilters(res.data.data)
        if(res.data.data.length)
          setPatientFilterId(res.data.data[0].id)
      })
      .catch(err => {
        addToast("Error getting filters", { appearance: "error", autoDismiss: true })
      })
  }

  const sendQuestionnaireToPatients = function (evt){
    evt.preventDefault()
    PatientWolfAPI.sendQuestionnaire(props.match.params.diagnosisId, props.match.params.regimenId, props.match.params.questionnaireId, patientFilterId)
      .then(res => {
        addToast(res.data.message, { appearance: "success", autoDismiss: true })
      })
      .catch(err => {
        addToast("Error sending questionnaire to patients", { appearance: "error", autoDismiss: true })
      })
  }

  const addQuestion = function (){
    const question = {
      title: "Question " + (questions.length + 1),
      description: "",
      answers: [],
    }

    setQuestions([...questions, question])
  }

  // TODO update name and description and min score of questionnaire of questionnaire
  const saveQuestionnaire = function (){
    PatientWolfAPI.updateQuestionnaire(props.match.params.diagnosisId, props.match.params.regimenId, props.match.params.questionnaireId, questionnaire.name, questionnaire.description, questionnaire.min_score, questions)
      .then(res => {
        addToast("Questionnaire saved successfully", { appearance: "success", autoDismiss: true })
      })
      .catch(err => {
        addToast("Error saving questionnaire", { appearance: "error", autoDismiss: true })
      })
  }

  const onInputchange = function(event) {
    const newQuestions = [...questions]

    const key = event.target.id.replace("_title", "").replace("_description", "")

    const value = event.target.id.replace(key + "_", "")

    newQuestions[key][value] = event.target.value

    setQuestions(newQuestions)
  }

  //TODO Simplify this
  const onAnswerInputchange = function(event) {
    const newQuestions = [...questions]

    const key = event.target.id.replace("_description", "").replace("_score", "")

    const questionId = event.target.id.split("_")[1]
    const answerId = event.target.id.split("_")[0]

    const value = event.target.id.replace(answerId + "_" + questionId + "_", "")

    newQuestions[questionId].answers[answerId][value] = event.target.value

    setQuestions(newQuestions)
  }

  const addAnswer = function (questionIndex){
    const newQuestions = [...questions]

    newQuestions[questionIndex].answers = [...newQuestions[questionIndex].answers, {
      description: "",
      score: 0
    }]

    setQuestions(newQuestions)
  }

  const deleteQuestion = function (questionId){
    const newQuestions = [...questions]

    newQuestions.splice(questionId, 1)

    setQuestions(newQuestions)
  }

  const deleteAnswer = function (questionId, answerId){
    const newQuestions = [...questions]

    newQuestions[questionId].answers.splice(answerId, 1)

    setQuestions(newQuestions)
  }

  const handleFilterInput = function (evt){
    setPatientFilterId(evt.target.value)
  }

  return  (
    <div className="main-container">
      <div className="patients-header">
        <h1>
          {questionnaire.name} (Qualifying score: {questionnaire.min_score}%)
        </h1>
        <Dialog
          buttonType="icon"
          icon={faEdit}
          title="Edit Diagnosis"
          onCloseDialog={() => getQuestionnaire()}
        >
          <EditQuestionnaire initialValue={questionnaire} />
        </Dialog>
      </div>
      <p>
        {questionnaire.description}
      </p>
      <div>
        {questions.map((question, index) => {
          return (
            <Card className="mb-2">
              <Card.Body key={index}>
                <div className="form-row mb-1">
                  <div className="col">
                    <input className="form-control" onChange={onInputchange} type="text" placeholder={question.title} id={index + "_title"} name={index + "_title"}  />
                  </div>
                  <button onClick={() => deleteQuestion(index)} className="btn btn-danger">
                    Delete Question
                  </button>
                </div>
                <div className="form-row mb-1">
                  <div className="col">
                    <input onChange={onInputchange} type="text" placeholder={question.description} id={index + "_description"} name={index + "_description"} className="form-control" />
                  </div>
                </div>
                <div>
                  {question.answers.map((answer, answer_index) => {
                    return (
                      <div key={index + "_" +answer_index} className="form-row mb-1">
                        <div className="col">
                          <input onChange={onAnswerInputchange} placeholder={answer.description} type="text" id={answer_index + "_" + index + "_description"} name={answer_index + "_" + index + "_description"} className="form-control" />
                        </div>
                        <div className="col">
                          <input onChange={onAnswerInputchange} placeholder={answer.score} type="number" id={answer_index + "_" + index + "_score"} name={answer_index + "_" + index + "_score"} className="form-control" />
                        </div>
                        <button onClick={() => deleteAnswer(index, answer_index)} className="btn btn-danger">
                          Delete Answer
                        </button>
                      </div>
                    )
                  })}
                </div>
                <div>
                  <button className="btn btn-primary" onClick={() => addAnswer(index)}>
                    Add answer
                  </button>
                </div>
              </Card.Body>
            </Card>
          )
        })}

        <button className="btn btn-primary mr-1" onClick={saveQuestionnaire}>
          Save
        </button>
        <button className="btn btn-primary" onClick={addQuestion}>
          Add question
        </button>
      </div>
      <Accordion>
        <Accordion.Toggle as={Button} eventKey="0" className="mt-1 mb-1">
          Send to Patients
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card>
            <Card.Body>
              <form onSubmit={sendQuestionnaireToPatients}>
                <div className="form-row">
                  <div className="col">
                    <select onInput={handleFilterInput} className="form-control" name="patient_filter" id="patient_filter">
                      {filters.map((filter) => {
                        return <option key={filter.id} value={filter.id}>{filter.name}</option>
                      })}
                    </select>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Accordion.Collapse>
      </Accordion>
    </div>
  )
}

export default withRouter(Questionnaire);