import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import PatientWolfAPI from "../../../services/patientWolfApi";
import {Link, withRouter} from "react-router-dom";
import Avatar from "react-avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay, faCheck, faEdit, faEnvelope, faHome, faTimes, faUsers} from "@fortawesome/free-solid-svg-icons";
import "./patients.css"
import DatePipes from "../../../services/datePipes";
import List from "../../../components/list/List";
import PhonePipes from "../../../services/phonePipes";
import {useToasts} from "react-toast-notifications";
import EditPhysician from "../physicians/editPhysician";
import Dialog from "../../../components/dialog/dialog";
import SendQuestionnaire from "./sendQuestionnaire";

const Patient = (props) => {
  const [patient, setPatient] = useState({})
  const [lastCalls, setLastCalls] = useState([])
  const { addToast } = useToasts();

  const getLastCalls = function (){
    PatientWolfAPI.getLastCalls(props.match.params.patientId)
      .then(res => {
        setLastCalls(res.data.data)
      })
      .catch(err => {
        addToast('Error getting last calls', { appearance: 'error', autoDismiss: true });
      })
  }

  const getPatient = () => {
    PatientWolfAPI.getPatient(props.match.params.patientId)
      .then(res => {
        res.data.data.contacts.forEach((contact) => {
          if(contact.contact_type_id === 1)
            contact.contact_value = PhonePipes.formatPhone(contact.contact_value)
        })
        res.data.data.devices.forEach(element => {
          if(element.device)
            element.manufacturer = element.device.manufacturer.name
        })
        res.data.data.insurances.forEach(element => {
          element.name = element.insurance.name
        })
        setPatient(res.data.data)
      })
      .catch(err => {
        addToast('Error getting patient', { appearance: 'error', autoDismiss: true });
      })
  }

  useEffect(() => {
    getPatient()
    getLastCalls()
  }, [])

  return (
    <div className="main-container">
      <div className="col-12">
        <Card>
          <Card.Body>
            <div className="float-right">
              <div className="d-flex">
                <Dialog title="Send Questionnaire" onCloseDialog={() => getPatient()}>
                  <SendQuestionnaire patientId={props.match.params.patientId} />
                </Dialog>
                <Link className="btn btn-primary ml-2" to={props.history.location.pathname + "/edit"}>
                  Edit
                </Link>
              </div>
            </div>
            <div className="d-flex">
              <div className="mr-5">
                <Avatar name={patient.name} round={true} size="150" />
              </div>
              <div className="d-flex flex-column justify-content-around container-fluid">
                <div className="d-flex justify-content-between">
                  <h1 className="patient-name">
                    {patient.name}
                  </h1>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="mr-5 d-flex">
                    <div>
                      <FontAwesomeIcon className="patients-header-icon" icon={faHome} />
                    </div>
                    <p>
                      Address:<br />
                      {patient.address_line_1}<br />
                      {patient.address_line_2 ? (
                        <React.Fragment>
                          {patient.address_line_2}<br />
                        </React.Fragment>
                      ) : null}
                      {patient.city}, {patient.state}, {patient.zip}
                    </p>
                  </div>
                  <div className="mr-5 d-flex">
                    <div>
                      <FontAwesomeIcon className="patients-header-icon" icon={faEnvelope} />
                    </div>
                    <p>
                      Email: <br />
                      {patient.email}
                    </p>
                  </div>
                  <div className="mr-5 d-flex">
                    <div>
                      <FontAwesomeIcon className="patients-header-icon" icon={faUsers} />
                    </div>
                    <p>
                      Marital Status: <br />
                      {patient.marital_status}
                    </p>
                  </div>
                  <div className="mr-5 d-flex">
                    <div>
                      <FontAwesomeIcon className="patients-header-icon" icon={faCalendarDay} />
                    </div>
                    <p>
                      Born on {DatePipes.friendlyDOB(patient.date_of_birth)}<br />
                      ({DatePipes.pipeAgeFromDOB(patient.date_of_birth)})
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <div className="col-6">
          <Card>
            <Card.Header>
              Pharmacy
            </Card.Header>
            <Card.Body>
              <p>
                {patient.pharmacy_name}<br />
                {patient.pharmacy_phone}<br />
                {patient.pharmacy_address_line_1}<br />
                {patient.pharmacy_address_line_2 ? (
                  <React.Fragment>
                    {patient.pharmacy_address_line_2}<br />
                  </React.Fragment>
                ) : null}
                {patient.pharmacy_city}{patient.pharmacy_state ? ", " + patient.pharmacy_state : null}{patient.pharmacy_zip ? ", " + patient.pharmacy_zip : null}
              </p>
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Header>
              Contacts
            </Card.Header>
            <Card.Body>
              <List withLink={false} items={patient.contacts} headers={['name', 'contact_value']} />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <div className="col-6">
          <Card>
            <Card.Header>
              Regimens
            </Card.Header>
            <Card.Body>
              <List withLink={true} linkAppend="/regimen/" items={patient.regimens} headers={['name']} noItemMessage="This patient is not enrolled in any regimens" />
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Header>
              Devices
            </Card.Header>
            <Card.Body>
              <List withLink={false} items={patient.devices} headers={['manufacturer', 'status', 'tracking_number']} />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="col-6">
          <Card>
            <Card.Header>
              Prescriptions
            </Card.Header>
            <Card.Body>
              <List headers={['name']} items={patient.prescriptions} />
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Header>
              Symptoms
            </Card.Header>
            <Card.Body>
              <List headers={['name']} items={patient.symptoms} />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="col-6">
          <Card>
            <Card.Header>
              Other Info
            </Card.Header>
            <Card.Body>
              SSN: {patient.ssn}<br />
              Gender: {patient.gender}<br />
              Shoe Size: {patient.shoe_size}<br />
              Shirt Size: {patient.shirt_size}<br />
              Height: {patient.height} inches<br />
              Best time to contact: From {patient.contact_from} to {patient.contact_to}<br />
              In iConnect: {patient.in_iconnect ? <FontAwesomeIcon className="patients-header-icon" icon={faCheck} /> : <FontAwesomeIcon className="patients-header-icon" icon={faTimes} />}<br />
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Header>
              Last 5 Calls
            </Card.Header>
            <Card.Body>
              <List headers={['name', 'outbound_to']} items={lastCalls} />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="col-6">
          <Card>
            <Card.Header>
              Insurance
            </Card.Header>
            <Card.Body>
              <List headers={['name', 'insurance_number', 'effective_date']} items={patient.insurances} />
            </Card.Body>
          </Card>
        </div>
        <div className="col-6">
          <Card>
            <Card.Header>
              Physician
            </Card.Header>
            <Card.Body>
              {patient.physician ? <div>
                {patient.physician.name}<br />
                {patient.physician.email}<br />
                NPI: {patient.physician.npi}<br />
                {patient.physician.address_line_1}<br />
                {patient.physician.address_line_2 ? (
                  <React.Fragment>
                    {patient.physician.address_line_2}<br />
                  </React.Fragment>
                ) : null}
                {patient.physician.city}, {patient.physician.state}, {patient.physician.zip}
                {patient.physician.contacts.map(element => {
                  return (
                    <div>
                      {element.name}: {PhonePipes.formatPhone(element.contact_value)}
                    </div>
                  )
                })}
              </div> : null}
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Patient);