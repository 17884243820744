import React from "react";
import {withRouter} from "react-router-dom";
import {useToasts} from "react-toast-notifications";
import PatientWolfAPI from "../../../../services/patientWolfApi";

const DeleteRegimenItem = ({regimenItem, match, afterSubmit}) => {
  const { addToast } = useToasts();

  const handleSubmit = () => {
    PatientWolfAPI.deleteRegimenItem(match.params.diagnosisId, match.params.regimenId, regimenItem.id)
      .then(res => {
        addToast('Deleted schedule item', { appearance: 'success', autoDismiss: true });
        afterSubmit()
      })
      .catch(err => {
        addToast('There was an error deleting the schedule item', { appearance: 'error', autoDismiss: true });
      })
  }

  return (
    <div>
        Are you sure you want to delete schedule item "{regimenItem.name}"?
        <div className="d-flex justify-content-between">
            <button className="btn btn-primary" onClick={() => afterSubmit()}>
                Cancel
            </button>
            <button className="btn btn-danger" onClick={() => handleSubmit()}>
                Delete
            </button>
        </div>
    </div>
  )
}

export default withRouter(DeleteRegimenItem);