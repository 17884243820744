import React from "react";
import {Card} from "react-bootstrap";

const BoxStat = (props) => {
  return  (
    <div className="col-3">
      <Card>
        <Card.Body>
          <h5>
            {props.title}
          </h5>
          <h3>
            {props.value}
          </h3>
          <p>
            {props.description}
          </p>
        </Card.Body>
      </Card>
    </div>
  )
}

export default BoxStat;