import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './dialog.css'

const Dialog = ({buttonType, icon, title, component, children, onCloseDialog = () => {}}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    onCloseDialog()
    setShow(false)
  };
  const handleShow = () => setShow(true);

  const dialogContent = React.cloneElement(children, {afterSubmit: () => handleClose()})

  return (
    <div>
      {(buttonType === 'icon')
          ? <FontAwesomeIcon className="dialog-icon" icon={icon} onClick={handleShow} />
          : <button className="btn btn-primary" onClick={handleShow}>{title}</button>}

      <Modal size={'xl'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dialogContent}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Dialog;